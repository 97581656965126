import { FaTachometerAlt, FaGem, FaList } from 'react-icons/fa';
const NavigasiList = [
    {
        "icon": <FaList />,
        "title": "Modul Kepegawaian",
        "submenu": [
            {
                "title": "Data Potongan",
                "path": "/DataPotongan"
            },
            {
                "title": "Data Tambahan",
                "path": "/DataTambahan"
            },
            {
                "title": "Data Lembur",
                "path": "/DataLembur"
            }
        ]
    },{
        "icon": <FaList />,
        "title": "Modul Payroll",
        "submenu": [
            {
                "title": "Rekap Absensi",
                "path": "/reakapAbsensi"
            },
            {
                "title": "Data KBB",
                "path": "/ListKBB"
            },
            {
                "title": "Buat Perhitungan Payroll",
                "path": "/BuatPerhitunganPayroll"
            }
        ]
    }
]

export default (NavigasiList);