import React, { Component,useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import dataDummy from './dummyData'
import moment from 'moment';
import { MdFirstPage,MdChevronLeft,MdChevronRight,MdLastPage } from 'react-icons/md';

class Realisasi extends Component {
    constructor(props) {
        super(props);
        this.headerRef = [];
        this.bodyRef = [];
        this.myRef = React.createRef();
        this.state = {
            lebar:'',
            lebarKiri:'',
            lebarKanan:'',
            data:false,
            prmDataStart:0,
            prmDataEnd:3,
            maxDataLength:null
        };
    }
    componentDidMount = async () =>  {
        await this.setState({
            ...this.state,
            lebar:this.myRef.current.clientWidth,
            lebarKiri:this.myRef.current.clientWidth/100*30,
            lebarKanan:this.myRef.current.clientWidth/100*70
        });
        this.setState({
            ...this.state,
            data:true,
            maxDataLength:dataDummy[0].REALISASI.length
        });
    }
    nextData = () => {
        this.setState({
            ...this.state,
            prmDataStart:this.state.prmDataStart+1,
            prmDataEnd:this.state.prmDataEnd+1
        });
    }
    lastData = () => {
        this.setState({
            ...this.state,
            prmDataStart:this.state.maxDataLength-4,
            prmDataEnd:this.state.maxDataLength-1
        });
    }
    PrevData = () => {
        this.setState({
            ...this.state,
            prmDataStart:this.state.prmDataStart-1,
            prmDataEnd:this.state.prmDataEnd-1
        });
    }
    firstData = () => {
        this.setState({
            ...this.state,
            prmDataStart:0,
            prmDataEnd:3
        });
    }
    render() {
        console.log(this.state.prmDataStart,this.state.prmDataEnd);
        return (
            <div>
                <div className="container-fluid" style={{padding:0,height:"80vh",overflowX:"hidden",overflowY:"scroll"}} ref={this.myRef}>
                    {/* <button className="btn btn-secondary btn-block" onClick={()=> this.nextData()}>
                        <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                        <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Test click</span>
                    </button> */}
                    {
                        this.state.data!=false&&
                        <div>
                            <div style={{padding:0,display:"inline-flex",position:"fixed"}}>
                                <div style={{width:this.state.lebar/100*30}}>
                                    <div style={{width:"100%",height:"44pt",display:"flex",justifyContent:"flex-start",alignItems:"center",backgroundColor:"#ffffff",padding:10,border:"1px solid #c9c9c9",position:"sticky"}}>
                                        <span style={{fontSize:"14pt"}}>Nama Karyawan</span>
                                    </div>
                                </div>
                                <div style={{width:this.state.lebar/100*70}}>
                                    <div style={{width:"100%",display:"inline-flex"}}>
                                        {dataDummy[0].REALISASI.length > 0 && dataDummy[0].REALISASI.map((header,indexX) =>
                                            (indexX >= this.state.prmDataStart && indexX <= this.state.prmDataEnd) &&
                                            <div key={indexX}>
                                                <div style={{width:this.state.lebarKanan/100*25,height:"44pt",display:"flex",justifyContent:"flex-start",alignItems:"center",backgroundColor:"#ffffff",border:"1px solid #c9c9c9",padding:10}}>
                                                    <span style={{fontSize:"14pt"}}>{moment(header.tanggal).format('DD MMM YYYY')}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div style={{padding:0,display:"inline-flex"}}>
                                <div style={{width:this.state.lebar/100*30}}>
                                    {dataDummy.length > 0 && dataDummy.map((dataDummy,index) =>
                                        <div key={index} style={{width:"100%",height:"44pt",display:"flex",justifyContent:"flex-start",alignItems:"center",backgroundColor:"#ffffff",padding:10,border:"1px solid #c9c9c9"}}>
                                            <span style={{fontSize:"14pt"}}>{`${dataDummy.NIK}-${dataDummy.NAMA}`}</span>
                                        </div>
                                    )}
                                </div>
                                <div style={{width:this.state.lebar/100*70}}>
                                    {dataDummy.length > 0 && dataDummy.map((dataDummy,index) =>
                                        <div key={index} style={{width:"100%",display:"inline-flex"}}>
                                            {dataDummy.REALISASI.length > 0 && dataDummy.REALISASI.map((REALISASI,index2) =>
                                            (index2 >= this.state.prmDataStart && index2 <= this.state.prmDataEnd) &&
                                                <div key={index2}>
                                                    <div style={{width:this.state.lebarKanan/100*25,height:"44pt",display:"flex",justifyContent:"flex-start",alignItems:"center",backgroundColor:"#ffffff",border:"1px solid #c9c9c9",padding:10}}>
                                                        <Input type="select" name="select" id="exampleSelect">
                                                            <option>{REALISASI.starttime!='-'?`${REALISASI.nama_jadwal} (${moment(REALISASI.starttime).format('HH:mm')}-${moment(REALISASI.endtime).format('HH:mm')})`:`${REALISASI.nama_jadwal}`}</option>
                                                            <option>shift 1 (09:00 - 17:00)</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </Input>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="container-fluid" style={{padding:0}}>
                    <div className="row">
                        <div className="col-10">
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <div className="col-3"></div>
                                <div className="col-2">
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <button className="myBtn" onClick={()=> this.firstData()} disabled={this.state.prmDataStart>0?false:true}>
                                            <MdFirstPage size="2em" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <button className="myBtn" onClick={()=> this.PrevData()} disabled={this.state.prmDataStart>0?false:true}>
                                            <MdChevronLeft size="2em" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <button className="myBtn" onClick={()=> this.nextData()} disabled={this.state.prmDataEnd!=(this.state.maxDataLength-1)?false:true}>
                                            <MdChevronRight size="2em" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <button className="myBtn" onClick={()=> this.lastData()} disabled={this.state.prmDataEnd!=(this.state.maxDataLength-1)?false:true}>
                                            <MdLastPage size="2em" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Realisasi;