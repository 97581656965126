import React, { Component } from 'react';
import axios from "axios";
import {
    Row,
    Col
} from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

class ListKBB extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // overlay state
        prmLoading:false,
        // page state
        pagination:true,
        highlight:true,
        striped:false,
        loading:false,
        noHeader:true,
        fixedHeader:false,
        dataListKBB:[],
      };
    }
    componentDidMount = () => {
        this.setState({
          ...this.state,
          loading:true,
        });
        axios
        .get(`${localStorage.getItem("APIROUTE")}/jghris/listkbb`)
        .then(result => {
          this.setState({
            ...this.state,
            dataListKBB: result.data.dataListKBB,
            loading:false,
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
    toDetailKBB = (data) => {
        this.props.history.push({
            pathname: "/DataKBB",
            search: `?ID=${data.id_kbb}`
        })
    }
    render() {
        const DataButton = (data) => (
          <div>
            <button className="myBtn" onClick={()=> this.toDetailKBB(data)}><i className="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i></button>
          </div>
        );
        const columns = [
          {
            name: 'Nomor KBB',
            selector: 'nomor_kbb',
            sortable: true,
          },
          {
            name: 'Tanggal Pembuatan KBB',
            selector: 'tanggal_buat_kbb',
            sortable: true,
          },
          {
            name: 'Periode KBB',
            selector: 'periodeKBB',
            sortable: true,
          },
          {
            name: 'Status KBB',
            selector: 'status_kbb',
            sortable: true,
          },
          {
            name: 'User',
            selector: 'nama_user',
            sortable: true,
          },
          {
            name: 'Tool',
            button: true,
            cell: row => DataButton(row),
          },
        ];
        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-header">
                                    <Row>
                                        <Col xs="12" sm="12" md="12" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                        <span style={{fontWeight:"bold"}}>List Data KBB</span>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body">
                                    <DataTableExtensions
                                        columns={columns}
                                        data={this.state.dataListKBB}
                                        print={false}
                                        exportHeaders={false}
                                        export={false}
                                    >
                                        <DataTable
                                        defaultSortField="title"
                                        pagination={this.state.pagination}
                                        highlightOnHover={this.state.highlight}
                                        striped={this.state.striped}
                                        progressPending={this.state.loading}
                                        noHeader={this.state.noHeader}
                                        fixedHeader={this.state.fixedHeader}
                                        fixedHeaderScrollHeight="300px"
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ListKBB;