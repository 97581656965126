import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingRoutes from './routes/LoadingRoutes';
import Login from './pages/login';
import Main from './pages/main';
import { BrowserRouter as Router } from "react-router-dom";

class Auth extends Component {
    componentDidMount = () =>  {
        let prmLogin = localStorage.getItem("authToken")
        if(prmLogin===null){
            this.props.dispatch({ type: "SETAUTHTOKEN", payload: "login" });
        } else if(prmLogin!=="login"){
            const dataToSend2 = {
              NIK: prmLogin
            };
            axios
            .post(`${localStorage.getItem("APIROUTE")}/jghris/getAkses`, dataToSend2, {
              headers: {
                "Access-Control-Allow-Origin": "*"
              }
            })
            .then(async  result2 => {
                await this.setState({
                  ...this.state,
                  buttonLoginPrm:false,
                  buttonLoginText:"Login"
                });
                await this.props.dispatch({ type: "SETUSERINFO", payload: result2.data.userinfo });
                await localStorage.setItem("authToken",prmLogin)
                this.props.dispatch({ type: "SETAUTHTOKEN", payload: prmLogin });
            })
            .catch(error => {
              console.log(error);
              console.log(this.props);
            });
        } 
    }
    render() {
        if(this.props.authToken === null){
            return(
                <Router>
                    <LoadingRoutes />
                </Router>
            )
        } else if(this.props.authToken === "login"){
            return(
                <Router>
                    <Login />
                </Router>
            )
        } else{
            return(
                <Router>
                    <Main />
                </Router>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authToken: state.reducer.authToken
    };
};
  
export default connect(mapStateToProps)(Auth);