import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import {
    Row,
    Col,
    Collapse
} from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

const DatePicker = require("reactstrap-date-picker");

class rekapFingerDanRealisasi extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // base state
        dataPerhitunganPayroll: [],
        periodeAwal:null,
        periodeAkhir:null,
        prmLoading:false
        // base state --
      };
    }
    componentDidMount = () =>  {
      let startTime = moment(`${`2019-12-17`} ${`09:40:42`}`)
      let finishTime = moment(`${`2019-12-17`} ${`09:10:00`}`)
      let c = startTime.diff(finishTime, 'minutes')
    }
    handleChangePeriodeAwal = (value, formattedValue) => {
        this.setState({
            ...this.state,
            periodeAwal:formattedValue
        })
    }
    handleChangePeriodeAkhir = (value, formattedValue) => {
        this.setState({
            ...this.state,
            periodeAkhir:formattedValue
        })
    }
    BuatPerhitunganPayroll = () =>  {
        this.setState({
          ...this.state,
          prmLoading:true,
          buttonSave:""
        });
        const dataToSend = {
            STARTDATE:this.state.periodeAwal,
            ENDDATE:this.state.periodeAkhir
        };
        if(dataToSend.STARTDATE === null || dataToSend.ENDDATE === null){
            alert("Periode awal atau periode akhir tidak boleh kosong")
            this.setState({
                ...this.state,
                prmLoading:false
            });
        } else {
            axios
            .post(`${localStorage.getItem("APIROUTE")}/jghris/getDataRekapAbsensi`, dataToSend, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(async result => {
                let hasilPerhitunganPayroll = await this.prosesHitungPotongan(result.data.dataFinal)
                console.log(hasilPerhitunganPayroll);
                this.setState({
                    ...this.state,
                    prmLoading:false,
                    dataPerhitunganPayroll:hasilPerhitunganPayroll,
                    periodeAwal:null,
                    periodeAkhir:null,
                    formPerhitunganToggle:false
                });
            })
            .catch(error => {
                console.log(error);
                console.log(this.props);
            });
        }
    }
    prosesHitungPotongan = (data) =>  {
        let dataToProcess = data
        for(let i=0;i<dataToProcess.length;i++){
            let dataRealisasiRaw = dataToProcess[i].REALISASI
            for(let i1=0;i1<dataRealisasiRaw.length;i1++){
                if(dataRealisasiRaw[i1].id_jadwal=="-"){
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:"NONE"}
                } else {
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataRealisasiRaw[i1].nama_jadwal}
                }
                let fingerData = dataToProcess[i].REALISASI[i1].fingerData
                if(fingerData.length>0){
                    let startTime = moment(`${fingerData[0].jam}`)
                    let finishTime = moment(`${fingerData[fingerData.length-1].jam}`)
                    let jamKerjaInMinutes = parseInt(finishTime.diff(startTime, 'minutes'))
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jamKerjaInMinutes:jamKerjaInMinutes}
                } else {
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jamKerjaInMinutes:'-'}
                }
            }
        }
        return dataToProcess
    }
    formatNumber = (num) =>  {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    hitungJamKerja = (jamKerja) =>  {
        let dalamJam = parseInt(jamKerja/60)
        let sisaMenit = jamKerja%60
        let totalJamKerja = `${dalamJam} jam - ${sisaMenit} Menit`
        return totalJamKerja
    }
    render() {
        return (
            <div>
                <div className="overlayMask" style={{visibility:this.state.prmLoading===true?"visible":"hidden"}}>
                <ScaleLoader
                    height={90}
                    width={20}
                    radius={10}
                    margin={10}
                    color={'#ffffff'}
                />
                </div>
                <div style={{padding:"10px",position:"sticky",top:0,zIndex:99,backgroundColor:"#f8f9fa"}}>
                    <div className="card" style={{width:"100%"}}>
                        <div className="card-body" style={{padding:10}}>
                            <Row style={{paddingTop:5,paddingBottom:5}}>
                                <Col>
                                    <Row>
                                        <Col xs="6" sm="6" md="6"><span>Periode Awal</span></Col>
                                        <Col xs="6" sm="6" md="6"><span>Periode Akhir</span></Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" sm="6" md="6">
                                            <DatePicker id = "periodeAwal" 
                                                value   = {this.state.periodeAwal}
                                                dateFormat = {`YYYY-MM-DD`}
                                                onChange= {(v,f) => this.handleChangePeriodeAwal(v, f)}
                                            />
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <DatePicker id = "example-periodeAkhir" 
                                                value   = {this.state.periodeAkhir}
                                                dateFormat = {`YYYY-MM-DD`}
                                                onChange= {(v,f) => this.handleChangePeriodeAkhir(v, f)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{paddingTop:5,paddingBottom:5}}>
                                <Col>
                                    <button className="btn btn-outline-dark btn-lg btn-block" onClick={() => this.BuatPerhitunganPayroll()}>
                                        {`Lihat Rekap Absensi`}
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div>
                    <Row>
                        <Col xs="12" sm="12" md="12">
                            {this.state.dataPerhitunganPayroll.length > 0 && this.state.dataPerhitunganPayroll.map((dataPayroll,index) =>
                            <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}} key={index}>
                                <div className="card">
                                    <div className="card-header">
                                        <span style={{fontWeight:"bold"}}>{`Detail Kehadiaran ${dataPayroll.NAMA} (${dataPayroll.NIK})`}</span>
                                    </div>
                                    <div className="card-body" style={{padding:0}}>
                                        <Row style={{paddingTop:5,paddingBottom:10}}>
                                            <Col xs="12" sm="12" md="12" className="detailKaryawanAlignCenter">
                                                <div style={{width:"75vw",overflow:"scroll",overflowX:"scroll",overflowY:"hidden"}}>
                                                    <table>
                                                        <tr>
                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                            <td key={index} style={{border:"1px solid #000"}} key={index2}>
                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.tanggal}</span>
                                                            </div>
                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{moment(dataRealisasi.tanggal).format('ddd')}</span>
                                                            </div>
                                                            </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                                <td key={index} style={{backgroundColor:dataRealisasi.statusAbsensi==false?"#ffd1d1":"#ffffff",border:"1px solid #000"}} key={index2}>
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.jadwalToShow}</span>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                                <td key={index} style={{backgroundColor:dataRealisasi.statusAbsensi==false?"#ffd1d1":"#ffffff",border:"1px solid #000"}} key={index2}>
                                                                    {dataRealisasi.fingerData.length > 0 ?
                                                                    dataRealisasi.fingerData.length > 0 && dataRealisasi.fingerData.map((fignerData,index3) =>
                                                                    index3==0?
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}} key={index3}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{`${fignerData.jam.substring(11, 16)}`}</span>
                                                                    </div>
                                                                    :
                                                                    fignerData.jam.substring(11, 13) != dataRealisasi.fingerData[index3-1].jam.substring(11, 13)&&
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}} key={index3}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{`${fignerData.jam.substring(11, 16)}`}</span>
                                                                    </div>
                                                                    ):
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{`-`}</span>
                                                                    </div>}
                                                                </td>
                                                            )}
                                                        </tr>
                                                        <tr>
                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                                <td key={index} style={{backgroundColor:dataRealisasi.statusAbsensi==false?"#ffd1d1":"#ffffff",border:"1px solid #000"}} key={index2}>
                                                                    {dataRealisasi.jamKerjaInMinutes == '-' ?
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{`0 jam kerja`}</span>
                                                                    </div>
                                                                    :
                                                                    <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                        <span style={{fontWeight:"bold",textAlign:"center"}}>{this.hitungJamKerja(dataRealisasi.jamKerjaInMinutes)}</span>
                                                                    </div>
                                                                    }
                                                                </td>
                                                            )}
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default rekapFingerDanRealisasi;