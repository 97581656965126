import { FaTachometerAlt, FaGem, FaList } from 'react-icons/fa';
const NavigasiList = [
    {
        "icon": <FaList />,
        "title": "Data KBB",
        "path": "/ListKBB",
        "submenu": []
    },
]

export default (NavigasiList);