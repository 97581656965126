import React, { Component } from 'react';
import axios from "axios";
import './styles/App.scss';
import './App.css';
import "./assets/font-awesome/css/font-awesome.min.css";
import 'react-data-table-component-extensions/dist/index.css';
import { Provider } from "react-redux";
import store from "./redux/store";
import Auth from './auth';

class App extends Component {
    componentDidMount = async () =>  {
        await localStorage.removeItem("APIROUTE");
        axios
        .get(`${process.env.REACT_APP_LINK}`)
        .then(async result => {
            await localStorage.setItem("APIROUTE",result.data.APIROUTE)
        })
        .catch(error => {
          console.log(error);
        });
        // localStorage.setItem("APIROUTE",process.env.REACT_APP_LINK)
    }
    render() {
        return (
            <Provider store={store}>
                <Auth />
            </Provider>
        );
    }
}

export default App;
