import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { ScaleLoader } from 'react-spinners';

class SetupLokasiKerja extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // base state
        dataLokasiKerja: [],
        loadingLokasiKerja:false,
        // base state --
        // modal add
        prmModalAdd: false,
        // modal add --
        // modal edit
        prmModalEdit: false,
        // modal edit --
      };
    }
    render() {
        // data table recent purchase
        const LokasiKerjaColumns = [
          {
            name: 'Nomor PO',
            selector: 'nomor_po',
            sortable: true,
          },
          {
            name: 'Tanggal buat',
            selector: 'tanggal_buat',
            sortable: true,
          },
          {
            name: 'Vendor',
            selector: 'nama_vendor',
            sortable: true,
          },
          {
            name: 'PO Status',
            selector: 'po_status',
            sortable: true,
          },
        ];
        return (
            <div>
                <Modal isOpen={this.state.prmModalAdd} backdrop={"static"} size="xl">
                    <ModalHeader toggle={() => this.setState({...this.state,prmModalAdd:false})}>
                        Form Add Data Lokasi Kerja
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{paddingTop:10}}>
                            <Col xs="12" sm="12" md="12">
                                <h3>{this.props.prmModalBody}</h3>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:10}}>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="danger" 
                                    onClick={() => this.setState({...this.state,prmModalAdd:false})}
                                    block={true}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="success" 
                                    onClick={() => alert("it work's")}
                                    block={true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.prmModalEdit} backdrop={"static"} size="xl">
                    <ModalHeader toggle={() => this.setState({...this.state,prmModalEdit:false})}>
                        Form Edit Data Lokasi Kerja
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{paddingTop:10}}>
                            <Col xs="12" sm="12" md="12">
                                <h3>{this.props.prmModalBody}</h3>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:10}}>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="danger" 
                                    onClick={() => this.setState({...this.state,prmModalEdit:false})}
                                    block={true}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="success" 
                                    onClick={() => this.submitData()}
                                    block={true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-header">
                                    <Row>
                                        <Col xs="10" sm="10" md="10" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                        </Col>
                                        <Col xs="2" sm="2" md="2" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                            <button
                                                className="myBtn"
                                                style={{display:"flex",alignItems:"center",fontSize:25}}
                                                onClick={() => 
                                                    this.setState({...this.state,prmModalAdd:true})
                                                }
                                            >
                                                <i className="fa fa-plus-square" aria-hidden="true"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body">
                                    <DataTableExtensions
                                        columns={LokasiKerjaColumns}
                                        data={this.state.dataLokasiKerja}
                                        print={false}
                                        exportHeaders={false}
                                        export={false}
                                    >
                                    <DataTable
                                        defaultSortField="title"
                                        pagination={true}
                                        highlightOnHover={true}
                                        striped={false}
                                        progressPending={this.state.loadingLokasiKerja}
                                        noHeader={true}
                                        fixedHeader={false}
                                        paginationPerPage={5}
                                        paginationRowsPerPageOptions={[5]}
                                        fixedHeaderScrollHeight="300px"
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect()(SetupLokasiKerja);