const dataDummy = [
    {
        "NIK": "1084001",
        "NAMA": "Saidum",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "OFFICE BOY (OB) STAFF",
        "PosisiToShow": "OFFICE BOY (OB) STAFF",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-21 08:10:00",
                "endtime": "2019-08-21 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-22 08:10:00",
                "endtime": "2019-08-22 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-23 08:10:00",
                "endtime": "2019-08-23 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-26 08:10:00",
                "endtime": "2019-08-26 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-27 08:10:00",
                "endtime": "2019-08-27 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-28 08:10:00",
                "endtime": "2019-08-28 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-29 08:10:00",
                "endtime": "2019-08-29 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-30 08:10:00",
                "endtime": "2019-08-30 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-02 08:10:00",
                "endtime": "2019-09-02 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-03 08:10:00",
                "endtime": "2019-09-03 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-04 08:10:00",
                "endtime": "2019-09-04 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-05 08:10:00",
                "endtime": "2019-09-05 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-06 08:10:00",
                "endtime": "2019-09-06 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-09 08:10:00",
                "endtime": "2019-09-09 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-10 08:10:00",
                "endtime": "2019-09-10 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-11 08:10:00",
                "endtime": "2019-09-11 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-12 08:10:00",
                "endtime": "2019-09-12 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-13 08:10:00",
                "endtime": "2019-09-13 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-16 08:10:00",
                "endtime": "2019-09-16 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-17 08:10:00",
                "endtime": "2019-09-17 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-18 08:10:00",
                "endtime": "2019-09-18 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-19 08:10:00",
                "endtime": "2019-09-19 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-20 08:10:00",
                "endtime": "2019-09-20 17:00:00",
                "CODE": ""
            }
        ]
    },
    {
        "NIK": "1089001",
        "NAMA": "Siti Mariatun",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "TRAINER",
        "PosisiToShow": "TRAINER",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-21 09:10:00",
                "endtime": "2019-08-21 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-22 09:10:00",
                "endtime": "2019-08-22 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-23 09:10:00",
                "endtime": "2019-08-23 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-26 09:10:00",
                "endtime": "2019-08-26 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-27 09:10:00",
                "endtime": "2019-08-27 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-28 09:10:00",
                "endtime": "2019-08-28 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-29 09:10:00",
                "endtime": "2019-08-29 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-30 09:10:00",
                "endtime": "2019-08-30 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-02 09:10:00",
                "endtime": "2019-09-02 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-03 09:10:00",
                "endtime": "2019-09-03 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-04 09:10:00",
                "endtime": "2019-09-04 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-05 09:10:00",
                "endtime": "2019-09-05 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-06 09:10:00",
                "endtime": "2019-09-06 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-09 09:10:00",
                "endtime": "2019-09-09 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-10 09:10:00",
                "endtime": "2019-09-10 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-16 09:10:00",
                "endtime": "2019-09-16 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-17 09:10:00",
                "endtime": "2019-09-17 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-18 09:10:00",
                "endtime": "2019-09-18 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-19 09:10:00",
                "endtime": "2019-09-19 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-20 09:10:00",
                "endtime": "2019-09-20 17:00:00",
                "CODE": ""
            }
        ]
    },
    {
        "NIK": "1089002",
        "NAMA": "Asmawati",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "OFFICE GIRL (OG) STAFF",
        "PosisiToShow": "OFFICE GIRL (OG) STAFF",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-21 08:10:00",
                "endtime": "2019-08-21 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-22 08:10:00",
                "endtime": "2019-08-22 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-23 08:10:00",
                "endtime": "2019-08-23 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-26 08:10:00",
                "endtime": "2019-08-26 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-27 08:10:00",
                "endtime": "2019-08-27 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-03 08:10:00",
                "endtime": "2019-09-03 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-04 08:10:00",
                "endtime": "2019-09-04 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-05 08:10:00",
                "endtime": "2019-09-05 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-06 08:10:00",
                "endtime": "2019-09-06 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-09 08:10:00",
                "endtime": "2019-09-09 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-10 08:10:00",
                "endtime": "2019-09-10 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-11 08:10:00",
                "endtime": "2019-09-11 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-12 08:10:00",
                "endtime": "2019-09-12 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-13 08:10:00",
                "endtime": "2019-09-13 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-16 08:10:00",
                "endtime": "2019-09-16 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-17 08:10:00",
                "endtime": "2019-09-17 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-18 08:10:00",
                "endtime": "2019-09-18 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-19 08:10:00",
                "endtime": "2019-09-19 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-20 08:10:00",
                "endtime": "2019-09-20 17:00:00",
                "CODE": ""
            }
        ]
    },
    {
        "NIK": "1094002",
        "NAMA": "Bambang Yulianto",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "KEPALA GUDANG",
        "PosisiToShow": "KEPALA GUDANG",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-21 08:10:00",
                "endtime": "2019-08-21 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-22 08:10:00",
                "endtime": "2019-08-22 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-23 08:10:00",
                "endtime": "2019-08-23 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-26 08:10:00",
                "endtime": "2019-08-26 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-27 08:10:00",
                "endtime": "2019-08-27 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-28 08:10:00",
                "endtime": "2019-08-28 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-29 08:10:00",
                "endtime": "2019-08-29 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-08-30 08:10:00",
                "endtime": "2019-08-30 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-02 08:10:00",
                "endtime": "2019-09-02 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-03 08:10:00",
                "endtime": "2019-09-03 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-04 08:10:00",
                "endtime": "2019-09-04 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-05 08:10:00",
                "endtime": "2019-09-05 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-06 08:10:00",
                "endtime": "2019-09-06 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-09 08:10:00",
                "endtime": "2019-09-09 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-10 08:10:00",
                "endtime": "2019-09-10 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-11 08:10:00",
                "endtime": "2019-09-11 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-12 08:10:00",
                "endtime": "2019-09-12 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-13 08:10:00",
                "endtime": "2019-09-13 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-16 08:10:00",
                "endtime": "2019-09-16 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-17 08:10:00",
                "endtime": "2019-09-17 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-18 08:10:00",
                "endtime": "2019-09-18 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-19 08:10:00",
                "endtime": "2019-09-19 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "020",
                "nama_jadwal": "gudang ho",
                "starttime": "2019-09-20 08:10:00",
                "endtime": "2019-09-20 17:00:00",
                "CODE": ""
            }
        ]
    },
    {
        "NIK": "1095002",
        "NAMA": "Martha Iriani Ritonga",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "MERCHANDISER ( MD )",
        "PosisiToShow": "MERCHANDISER ( MD )",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-21 09:10:00",
                "endtime": "2019-08-21 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "033",
                "nama_jadwal": "IZIN TUGAS LUAR",
                "starttime": "2019-08-22 00:00:00",
                "endtime": "2019-08-22 23:59:59",
                "CODE": "D"
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-23 09:10:00",
                "endtime": "2019-08-23 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-26 09:10:00",
                "endtime": "2019-08-26 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-27 09:10:00",
                "endtime": "2019-08-27 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-28 09:10:00",
                "endtime": "2019-08-28 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-29 09:10:00",
                "endtime": "2019-08-29 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-08-30 09:10:00",
                "endtime": "2019-08-30 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-02 09:10:00",
                "endtime": "2019-09-02 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-03 09:10:00",
                "endtime": "2019-09-03 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-04 09:10:00",
                "endtime": "2019-09-04 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-05 09:10:00",
                "endtime": "2019-09-05 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-06 09:10:00",
                "endtime": "2019-09-06 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-09 09:10:00",
                "endtime": "2019-09-09 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-10 09:10:00",
                "endtime": "2019-09-10 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-11 09:10:00",
                "endtime": "2019-09-11 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-12 09:10:00",
                "endtime": "2019-09-12 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "033",
                "nama_jadwal": "IZIN TUGAS LUAR",
                "starttime": "2019-09-13 00:00:00",
                "endtime": "2019-09-13 23:59:59",
                "CODE": "D"
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-16 09:10:00",
                "endtime": "2019-09-16 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-17 09:10:00",
                "endtime": "2019-09-17 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-18 09:10:00",
                "endtime": "2019-09-18 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-19 09:10:00",
                "endtime": "2019-09-19 17:00:00",
                "CODE": ""
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "001",
                "nama_jadwal": "Senin-Jumat",
                "starttime": "2019-09-20 09:10:00",
                "endtime": "2019-09-20 17:00:00",
                "CODE": ""
            }
        ]
    },
    {
        "NIK": "1105001",
        "NAMA": "Apriana",
        "Store": "LOKASI19000001",
        "StoreToShow": "HO SCU",
        "Posisi": "ACCOUNTING SPV",
        "PosisiToShow": "ACCOUNTING SPV",
        "REALISASI": [
            {
                "tanggal": "2019-08-21",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-22",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-23",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-24",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-25",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-08-26",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-27",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-28",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-29",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-30",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-08-31",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-01",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-02",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-03",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-04",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-05",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-06",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-07",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-08",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-09",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-10",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-11",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-12",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-13",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-14",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-15",
                "id_jadwal": "034",
                "nama_jadwal": "OFF",
                "starttime": "-",
                "endtime": "-",
                "CODE": "O"
            },
            {
                "tanggal": "2019-09-16",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-17",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-18",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-19",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            },
            {
                "tanggal": "2019-09-20",
                "id_jadwal": "032",
                "nama_jadwal": "CUTI TAHUNAN",
                "starttime": "-",
                "endtime": "-",
                "CODE": "C"
            }
        ]
    }
]

export default (dataDummy);