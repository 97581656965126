import React, { Component } from 'react';
import axios from "axios";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import { ScaleLoader } from 'react-spinners';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { CSVReader } from "react-papaparse";
import { CSVLink, CSVDownload } from "react-csv";

const contohData = [
    ["NIK","NAMA","NOMINAL POTONGAN","KETERANGAN POTONGAN","TANGGAL POTONGAN"]
]

class DataPotonganPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // modal add
        prmModalAdd: false,
        csvReaderADD:false,
        dataAdd:[],
        prmButtonAdd:false,
        // modal add --
        // overlay state
        prmLoading:false,
        // page state
        pagination:true,
        highlight:true,
        striped:false,
        loading:false,
        noHeader:true,
        fixedHeader:false,
        dataListPotongan:[],
      };
    }
    componentDidMount = () => {
        this.setState({
          ...this.state,
          loading:true,
        });
        axios
        .get(`${localStorage.getItem("APIROUTE")}/jghris/listDataPotongan`)
        .then(result => {
            let newDataPotongan = result.data.dataListPotongan
            for(let i=0;i<newDataPotongan.length;i++){
                newDataPotongan[i] = {...newDataPotongan[i],besar_potongan_toshow:this.formatNumber(newDataPotongan[i].besar_potongan)}
            }
            this.setState({
                ...this.state,
                dataListPotongan: newDataPotongan,
                loading:false,
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
    refreshPage = () => {
        this.setState({
          ...this.state,
          loading:true,
        });
        axios
        .get(`${localStorage.getItem("APIROUTE")}/jghris/listDataPotongan`)
        .then(result => {
            let newDataPotongan = result.data.dataListPotongan
            for(let i=0;i<newDataPotongan.length;i++){
                newDataPotongan[i] = {...newDataPotongan[i],besar_potongan_toshow:this.formatNumber(newDataPotongan[i].besar_potongan)}
            }
            this.setState({
                ...this.state,
                dataListPotongan: newDataPotongan,
                loading:false,
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
    openAddModal = () =>{
        this.setState({
            ...this.state,
            prmModalAdd: true,
            csvReaderADD:false,
            dataAdd:[],
            prmButtonAdd:false,
        })
    }
    closeAddModal = () =>{
        this.setState({
            ...this.state,
            prmModalAdd: false,
            csvReaderADD:false,
            dataAdd:[],
            prmButtonAdd:false,
        })
    }
    toDetailKBB = (data) => {
        this.props.history.push({
            pathname: "/DataKBB",
            search: `?ID=${data.id_kbb}`
        })
    }
    cekData = async (data) => {
        let csvFormat =[
            "NIK",
            "NAMA",
            "NOMINAL POTONGAN",
            "KETERANGAN POTONGAN",
            "TANGGAL POTONGAN"
        ]
        let dataEror = 0
        if(data[0].data.length>5){
            dataEror++
        }
        for(let icek=0;icek<4;icek++){
            if(data[0].data[icek]!=csvFormat[icek]){
                dataEror++
            }
        }
        if(dataEror>0){
            alert("format csv tidak sesuai")
            await this.setState({
                ...this.state,
                csvReaderADD: true
            });
            this.setState({
                ...this.state,
                csvReaderADD: false
            });
        } else {
            this.handleOnDrop(data)
        }
    }
    handleOnDrop = async (data) => {
        let dataCSV=[]
        let noData = 0
        for(let i=1;i<data.length;i++){
            if(data[i].data[0]!=undefined&&data[i].data[0]!=''){
                let dataTopush1 = {
                    noData:noData,
                    NIK:data[i].data[0],
                    NAMA:data[i].data[1],
                    NOMINAL_POTONGAN:parseInt(data[i].data[2]),
                    NOMINAL_POTONGAN_TOSHOW:this.formatNumber(parseInt(data[i].data[2])),
                    KETERANGAN:data[i].data[3],
                    TANGGAL_POTONGAN:data[i].data[4]
                }
                dataCSV.push(dataTopush1)
                noData++
            }
        }
        await this.setState({
            ...this.state,
            dataAdd: dataCSV
        });
    }
    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    }
    handleOnRemoveFile = (data) => {
        this.setState({
            ...this.state,
            dataAdd: []
        });
    }
    removeFromAddData = (data) => {
        let lastData = this.state.dataAdd
        lastData.splice(data, 1);
        this.setState({
            ...this.state,
            dataAdd: lastData
        });
    }
    importPotongan = () =>{
        this.state.dataAdd.length>0?this.prosesImportPotongan():alert(`Data yang akan di import tidak boleh kosong !`)
    }
    prosesImportPotongan = () =>{
        this.setState({
            ...this.state,
            prmButtonAdd:true
        })
        const dataToSend = {
            IMPORTDATA:this.state.dataAdd
        };
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/importDataPotongan`, dataToSend, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(result => {
            alert(`Berhasil mengimport data potongan`)
            this.closeAddModal()
            this.refreshPage()
        })
        .catch(error => {
            console.log(error);
            console.log(this.props);
        });
    }
    formatNumber = (num) =>  {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    render() {
        const DataButton = (data) => (
            <div>
                <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                    <Tooltip id={`tooltip-${'top'}`}>
                        <strong>{'Edit data potongan'}</strong>
                    </Tooltip>
                    }
                >
                    <button className="myBtn" onClick={()=> this.toDetailKBB(data)}><i className="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i></button>
                </OverlayTrigger>
            </div>
        );
        const columns = [
            {
                name: 'NIK',
                selector: 'nik',
                sortable: true,
            },
            {
                name: 'NAMA',
                selector: 'nama_karyawan',
                sortable: true,
            },
            {
                name: 'NOMINAL POTONGAN',
                selector: 'besar_potongan_toshow',
                sortable: true,
            },
            {
                name: 'KETERANGAN',
                selector: 'ket_potongan',
                sortable: true,
                wrap:true,
            },
            {
                name: 'TANGGAL POTONGAN',
                selector: 'key_periode',
                sortable: true,
            },
            // {
            //     name: 'Tool',
            //     button: true,
            //     cell: row => DataButton(row),
            // },
        ];
        const DataButtonDataAdd = (data,index) => (
            <div>
                <OverlayTrigger
                    key={'left'}
                    placement={'left'}
                    overlay={
                    <Tooltip id={`tooltip-${'left'}`}>
                        <strong>{'Hapus data potongan'}</strong>
                    </Tooltip>
                    }
                >
                    {/* <button className="myBtn" onClick={()=> this.toDetailKBB(data)}><i className="fa fa-trash fa-2x" aria-hidden="true"></i></button> */}
                    <button className="myBtn" onClick={()=> this.removeFromAddData(index)}><i className="fa fa-trash fa-2x" aria-hidden="true"></i></button>
                </OverlayTrigger>
            </div>
        );
        const columnsDataAdd = [
            {
                name: 'NIK',
                selector: 'NIK',
                sortable: true,
            },
            {
                name: 'NAMA',
                selector: 'NAMA',
                sortable: true,
            },
            {
                name: 'NOMINAL POTONGAN',
                selector: 'NOMINAL_POTONGAN_TOSHOW',
                sortable: true,
            },
            {
                name: 'KETERANGAN',
                selector: 'KETERANGAN',
                sortable: true,
                wrap:true,
            },
            {
                name: 'TANGGAL POTONGAN',
                selector: 'TANGGAL_POTONGAN',
                sortable: true,
            },
            {
                name: 'Tool',
                button: true,
                cell: (row,index) => DataButtonDataAdd(row,index),
            },
        ];
        return (
            <div className="container-fluid">
                <Modal isOpen={this.state.prmModalAdd} backdrop={"static"} size="xl">
                    <ModalHeader toggle={() => this.closeAddModal()}>
                        Form Add Data Potongan
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <div>
                                    <CSVReader
                                        onDrop={this.cekData}
                                        onError={this.handleOnError}
                                        noDrag
                                        addRemoveButton
                                        removeButtonColor="#659cef"
                                        isReset={this.state.csvReaderADD}
                                        onRemoveFile={this.handleOnRemoveFile}
                                    >
                                        <span>Drop CSV file here or click to upload.</span>
                                    </CSVReader>
                                </div>
                                {this.state.dataAdd.length>0&&
                                    <div>
                                        <DataTableExtensions
                                            columns={columnsDataAdd}
                                            data={this.state.dataAdd}
                                            print={false}
                                            exportHeaders={false}
                                            export={false}
                                            filter={false}
                                        >
                                            <DataTable
                                                defaultSortField="title"
                                                pagination={this.state.pagination}
                                                highlightOnHover={this.state.highlight}
                                                striped={this.state.striped}
                                                progressPending={this.state.loading}
                                                noHeader={this.state.noHeader}
                                                fixedHeader={this.state.fixedHeader}
                                                fixedHeaderScrollHeight="300px"
                                            />
                                        </DataTableExtensions>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row style={{paddingTop:10}}>
                            <Col xs="4" sm="4" md="4">
                                <Button 
                                    color="danger" 
                                    onClick={() => this.closeAddModal()}
                                    block={true}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="4" sm="4" md="4">
                                <CSVLink data={contohData} filename={"Import Data Potongan.csv"}>
                                <Button 
                                    color="primary" 
                                    block={true}
                                >
                                    Download contoh file csv
                                </Button>
                                </CSVLink>;
                            </Col>
                            <Col xs="4" sm="4" md="4">
                                <Button 
                                    color="success" 
                                    onClick={() => this.importPotongan()}
                                    block={true}
                                >
                                    <ScaleLoader
                                        height={18}
                                        width={4}
                                        radius={2}
                                        margin={2}
                                        color={'#FFFFFF'}
                                        loading={this.state.prmButtonAdd}
                                    />
                                    {this.state.prmButtonAdd===true?"":"Import data potongan"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-header">
                                    <Row>
                                        <Col xs="11" sm="11" md="11" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                            <span style={{fontWeight:"bold"}}>Data Potongan</span>
                                        </Col>
                                        <Col xs="1" sm="1" md="1" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                            <div>
                                                <OverlayTrigger
                                                    key={'left'}
                                                    placement={'left'}
                                                    overlay={
                                                    <Tooltip id={`tooltip-${'left'}`}>
                                                        <strong>{'Tambah data potongan'}</strong>
                                                    </Tooltip>
                                                    }
                                                >
                                                    <button className="myBtn" onClick={() => this.openAddModal()}><i className="fa fa-plus-square fa-2x" aria-hidden="true"></i></button>
                                                </OverlayTrigger>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body">
                                    <DataTableExtensions
                                        columns={columns}
                                        data={this.state.dataListPotongan}
                                        print={false}
                                        exportHeaders={false}
                                        export={false}
                                    >
                                        <DataTable
                                        defaultSortField="title"
                                        pagination={this.state.pagination}
                                        highlightOnHover={this.state.highlight}
                                        striped={this.state.striped}
                                        progressPending={this.state.loading}
                                        noHeader={this.state.noHeader}
                                        fixedHeader={this.state.fixedHeader}
                                        fixedHeaderScrollHeight="300px"
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DataPotonganPage;