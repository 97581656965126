import React, { Component } from 'react';
import {
    Row,
    Col
} from 'reactstrap';

class DataResign extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DataResign;