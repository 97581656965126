import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../shadow.css';
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label
} from 'reactstrap';
import { ScaleLoader } from 'react-spinners';

class login extends Component {
    constructor(props) {
      super(props);
      this.state = {
          prmoverlay:false,
          APIROUTE:"",
          loginNik:"",
          loginPass:"",
          buttonLoginPrm:false,
          buttonLoginText:"Login",
      };
    }
    loginCheck = async () =>{
      const dataToSend = {
        NIK:this.state.loginNik,
        PASS:this.state.loginPass
      };
      if(dataToSend.NIK === "" || dataToSend.PASS === ""){
        alert("NIK dan password user tidak boleh kosong")
      } else {
        this.setState({
          ...this.state,
          buttonLoginPrm:true,
          buttonLoginText:""
        });
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/login`, dataToSend, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(async result => {
          if(result.data.status === "00"){
            await this.setState({
              ...this.state,
              buttonLoginPrm:false,
              buttonLoginText:"Login"
            });
            await this.props.dispatch({ type: "SETUSERINFO", payload: result.data.userinfo });
            await localStorage.setItem("authToken",result.data.nik)
            this.props.dispatch({ type: "SETAUTHTOKEN", payload: result.data.nik });
          } else {
            this.setState({
              ...this.state,
              buttonLoginPrm:false,
              buttonLoginText:"Login"
            });
            alert(result.data.message)
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.props);
        });
      }
    }
    handleChange = event =>  {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value
      });
    }
    render() {
        return (
            <div className="container-fluid" style={{backgroundColor:"#ecf0f5"}}>
                <Row className="justify-content-center align-items-center" style={{height:"100vh"}}>
                    <Col xs="10" sm="10" md="6">
                        <div className="card shadow-diffuse">
                            <div className="card-body">
                                <Row style={{marginTop:"5vh",marginBottom:"5vh"}}>
                                    <Col>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <blockquote className="blockquote text-center">
                                                <span style={{fontWeight:"bold",fontSize:"18pt"}}>JAYGEEGROUP</span>
                                                <footer className="blockquote-footer"><cite title="Source Title" style={{textAlign:"center"}}>Human Resources Management System</cite></footer>
                                            </blockquote>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom:"1vh"}}>
                                    <Col>
                                        <FormGroup>
                                            <Label for="loginNik">NIK</Label>
                                            <Input
                                                type="text"
                                                name="loginNik"
                                                id="loginNik"
                                                value={this.state.loginNik}
                                                onChange={this.handleChange}
                                                placeholder="Input NIK"
                                                disabled={this.state.buttonLoginPrm===true?true:false}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1vh",marginBottom:"1vh"}}>
                                    <Col>
                                        <FormGroup>
                                            <Label for="loginPass">Password</Label>
                                            <Input
                                                type="password"
                                                name="loginPass"
                                                id="loginPass"
                                                value={this.state.loginPass}
                                                onChange={this.handleChange}
                                                placeholder="Input Password"
                                                disabled={this.state.buttonLoginPrm===true?true:false}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1vh",marginBottom:"1vh"}}>
                                    <Col>
                                        <button className="btn btn-outline-dark btn-lg btn-block" onClick={() => this.loginCheck()}>
                                        <ScaleLoader
                                            height={18}
                                            width={4}
                                            radius={2}
                                            margin={2}
                                            color={'#FFFFFF'}
                                            loading={this.state.buttonLoginPrm}
                                        />
                                        {this.state.buttonLoginText}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
  return {
    authToken: state.reducer.authToken
  };
};

export default withRouter(connect(mapStateToProps)(login));