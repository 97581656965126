import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import { Redirect, Route, Switch } from 'react-router-dom';
// navigasi
import NavigasiList from './navigasiList';
import NavigationListHR from './navigationListHR';
import NavigationListSPVHR from './navigationListSPVHR';
import NavigationListADMIN from './navigationListADMIN';
import NavigationListCFO from './navigationListCFO';
// navigasi --
// modal
// import ModalPanel from './modalPanel';
// modal --
// pages
import Dashboard from './dashboard';
import SetupPerusahaan from './SetupPerusahaan';
import SetupAksesSistem from './SetupAksesSistem';
import SetupPaguGaji from './SetupPaguGaji';
import SetupLevelKerja from './SetupLevelKerja';
import SetupJadwalKerja from './SetupJadwalKerja';
import StupLokasiKerja from './StupLokasiKerja';
import SetupDepartemen from './SetupDepartemen';
import SetupJabatan from './SetupJabatan';
import DataPotongan from './DataPotongan';
import DataTambahan from './DataTambahan';
import DataLembur from './DataLembur';
import DataResign from './DataResign';
import DataPromosiDemosi from './DataPromosiDemosi';
import DataKontrak from './DataKontrak';
import DataKaryawan from './DataKaryawan';
import DataAbsensi from './DataAbsensi';
import ImportDataRealisasi from './ImportDataRealisasi';
import ImportDataFinger from './ImportDataFinger';
import GeneratePayroll from './GeneratePayroll';
import BuatPerhitunganPayroll from './BuatPerhitunganPayroll';
import RekapFingerDanRealisasi from './rekapFingerDanRealisasi';
import ListKBB from './listKBB';
import DataKBB from './dataKbb';
import Realisasi from './realisasi';
// pages --

class main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            navCollapse:false,
            fixedNav:"",
            dropdownState:false,
            sideBarState:false
        };
        this.baseState = {
            data:[],
            navCollapse:false,
            fixedNav:"",
            dropdownState:false,
            sideBarState:false
        };
    }
    sidebarToggle = () =>  {
      this.setState({
        ...this.state,
        sideBarState: !this.state.sideBarState
      });
    }
    logout = async () =>{
        await localStorage.removeItem("authToken");
        await this.props.dispatch({ type: "SETAUTHTOKEN", payload: "login" });
        await this.props.dispatch({ type: "SETUSERINFO", payload: null });
        this.props.history.push({
            pathname: "/"
        })
    }
    render() {
        return (
            <div style={{display:"flex",flexDirection:"row"}}>
                {/* <ModalPanel/> */}
                <div style={{height:"100vh"}}>
                    <ProSidebar
                    breakPoint="xl"
                    toggled={this.state.sideBarState}
                    onToggle={() => this.sidebarToggle()}
                    >
                        <SidebarHeader
                        onClick={() =>  {this.props.history.push({pathname: "/"})}}
                        style={{cursor:"pointer"}}
                        >
                            <div
                            style={{
                                padding: '24px',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                fontSize: 14,
                                letterSpacing: '1px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign:"center"
                            }}
                            >
                            {'JAYGEEGROUP-HRIS'}
                            </div>
                        </SidebarHeader>
                    
                        <SidebarContent>
                            <Menu iconShape="circle">
                                {
                                this.props.userinfo.previlege == "HR"?
                                NavigationListHR.length > 0 && NavigationListHR.map((NavigasiList,index) =>
                                    NavigasiList.submenu.length>0?
                                    <SubMenu title={NavigasiList.title} icon={NavigasiList.icon} key={index}>
                                        {NavigasiList.submenu.length > 0 && NavigasiList.submenu.map((Submenu,index1) =>
                                            <MenuItem
                                                onClick={() =>  {this.props.history.push({pathname: Submenu.path})}}
                                                key={index1}
                                            >
                                                {Submenu.title}
                                            </MenuItem>
                                        
                                        )}
                                    </SubMenu>
                                    :
                                    <MenuItem
                                        icon={NavigasiList.icon}
                                        onClick={() =>  {this.props.history.push({pathname: NavigasiList.path})}}
                                        key={index}
                                    >
                                        {NavigasiList.title}
                                    </MenuItem>
                                
                                ):
                                this.props.userinfo.previlege == "SPV-HR"?
                                NavigationListSPVHR.length > 0 && NavigationListSPVHR.map((NavigasiList,index) =>
                                    NavigasiList.submenu.length>0?
                                    <SubMenu title={NavigasiList.title} icon={NavigasiList.icon} key={index}>
                                        {NavigasiList.submenu.length > 0 && NavigasiList.submenu.map((Submenu,index1) =>
                                            <MenuItem
                                                onClick={() =>  {this.props.history.push({pathname: Submenu.path})}}
                                                key={index1}
                                            >
                                                {Submenu.title}
                                            </MenuItem>
                                        
                                        )}
                                    </SubMenu>
                                    :
                                    <MenuItem
                                        icon={NavigasiList.icon}
                                        onClick={() =>  {this.props.history.push({pathname: NavigasiList.path})}}
                                        key={index}
                                    >
                                        {NavigasiList.title}
                                    </MenuItem>
                                
                                ):
                                this.props.userinfo.previlege == "CFO"?
                                NavigationListCFO.length > 0 && NavigationListCFO.map((NavigasiList,index) =>
                                    NavigasiList.submenu.length>0?
                                    <SubMenu title={NavigasiList.title} icon={NavigasiList.icon} key={index}>
                                        {NavigasiList.submenu.length > 0 && NavigasiList.submenu.map((Submenu,index1) =>
                                            <MenuItem
                                                onClick={() =>  {this.props.history.push({pathname: Submenu.path})}}
                                                key={index1}
                                            >
                                                {Submenu.title}
                                            </MenuItem>
                                        
                                        )}
                                    </SubMenu>
                                    :
                                    <MenuItem
                                        icon={NavigasiList.icon}
                                        onClick={() =>  {this.props.history.push({pathname: NavigasiList.path})}}
                                        key={index}
                                    >
                                        {NavigasiList.title}
                                    </MenuItem>
                                
                                ):
                                this.props.userinfo.previlege == "ADMIN"&&
                                NavigationListADMIN.length > 0 && NavigationListADMIN.map((NavigasiList,index) =>
                                    NavigasiList.submenu.length>0?
                                    <SubMenu title={NavigasiList.title} icon={NavigasiList.icon} key={index}>
                                        {NavigasiList.submenu.length > 0 && NavigasiList.submenu.map((Submenu,index1) =>
                                            <MenuItem
                                                onClick={() =>  {this.props.history.push({pathname: Submenu.path})}}
                                                key={index1}
                                            >
                                                {Submenu.title}
                                            </MenuItem>
                                        
                                        )}
                                    </SubMenu>
                                    :
                                    <MenuItem
                                        icon={NavigasiList.icon}
                                        onClick={() =>  {this.props.history.push({pathname: NavigasiList.path})}}
                                        key={index}
                                    >
                                        {NavigasiList.title}
                                    </MenuItem>
                                
                                )
                                }
                            </Menu>
                        </SidebarContent>
                    
                        <SidebarFooter>
                            <div
                            className="sidebar-btn-wrapper"
                            style={{
                                padding: '20px 24px',
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}
                            >
                            <button className="myBtn" onClick={() => this.logout()}>
                                <i class="fa fa-power-off" aria-hidden="true" style={{color:"#adadad"}}></i>
                                <span style={{marginLeft:5,color:"#adadad",fontWeight:"bold"}}>Log out</span>
                            </button>
                            </div>
                        </SidebarFooter>
                    </ProSidebar>
                </div>
                <div style={{width:"100%",height:"100vh",padding:"0px",backgroundColor:"#f8f9fa"}}>
                    <div style={{width:"100%",height:"10vh",display:"flex",flexDirection:"row"}}>
                        <div className="d-xl-none" style={{height:"100%",width:"25%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <button className="myBtn" onClick={() => this.sidebarToggle()}>
                                <i class="fa fa-bars fa-2x" aria-hidden="true" style={{color:"#1D1D1D"}}></i>
                            </button>
                        </div>
                        <div style={{height:"100%",width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"center",paddingLeft:"20px"}}>
                            <span style={{fontWeight:"bold"}}>{this.props.history.location.pathname=="/"?"Dashboard":this.props.history.location.pathname.replace("/","")}</span>
                        </div>
                        <div style={{height:"100%",width:"100%",display:"flex",justifyContent:"flex-end",alignItems:"center",paddingRight:"20px"}}>
                            <span style={{fontWeight:"bold",marginRight:"10px"}}>{this.props.userinfo.Nama}</span><i class="fa fa-user-circle fa-3x" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div className="bodyWraper">
                        <Switch>
                            <Route exact path={"/"} component={Dashboard} />
                            <Route exact path={"/SetupPerusahaan"} component={SetupPerusahaan} />
                            <Route exact path={"/SetupAksesSistem"} component={SetupAksesSistem} />
                            <Route exact path={"/SetupPaguGaji"} component={SetupPaguGaji} />
                            <Route exact path={"/SetupLevelKerja"} component={SetupLevelKerja} />
                            <Route exact path={"/SetupJadwalKerja"} component={SetupJadwalKerja} />
                            <Route exact path={"/StupLokasiKerja"} component={StupLokasiKerja} />
                            <Route exact path={"/SetupDepartemen"} component={SetupDepartemen} />
                            <Route exact path={"/SetupJabatan"} component={SetupJabatan} />
                            <Route exact path={"/DataPotongan"} component={DataPotongan} />
                            <Route exact path={"/DataTambahan"} component={DataTambahan} />
                            <Route exact path={"/DataLembur"} component={DataLembur} />
                            <Route exact path={"/DataResign"} component={DataResign} />
                            <Route exact path={"/DataPromosiDemosi"} component={DataPromosiDemosi} />
                            <Route exact path={"/DataKontrak"} component={DataKontrak} />
                            <Route exact path={"/DataKaryawan"} component={DataKaryawan} />
                            <Route exact path={"/DataAbsensi"} component={DataAbsensi} />
                            <Route exact path={"/ImportDataRealisasi"} component={ImportDataRealisasi} />
                            <Route exact path={"/ImportDataFinger"} component={ImportDataFinger} />
                            <Route exact path={"/GeneratePayroll"} component={GeneratePayroll} />
                            <Route exact path={"/BuatPerhitunganPayroll"} component={BuatPerhitunganPayroll} />
                            <Route exact path={"/reakapAbsensi"} component={RekapFingerDanRealisasi} />
                            <Route exact path={"/ListKBB"} component={ListKBB} />
                            <Route exact path={"/DataKBB"} component={DataKBB} />
                            <Route exact path={"/realisasi"} component={Realisasi} />
                            <Redirect to={"/"} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      authToken: state.reducer.authToken,
      userinfo: state.reducer.userinfo
    };
  };
  
  export default withRouter(connect(mapStateToProps)(main));