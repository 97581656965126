import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { ScaleLoader } from 'react-spinners';

class SetupPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // base state
            dataPerusahaan: [],
            loadingPerusahaan:false,
            // base state --
            // modal add
            prmModalAdd: false,
            addNamaPerusahaan:null,
            addAlamatPerusahaan:null,
            addTelephonPerusahaan:null,
            prmButtonAdd:false,
            // modal add --
            // modal edit
            prmModalEdit: false,
            editIdPerusahaan:null,
            editNamaPerusahaan:null,
            editAlamatPerusahaan:null,
            editTelephonPerusahaan:null,
            prmButtonEdit:false,
            // modal edit --
        };
    }
    componentDidMount = () =>  {
        this.setState({
          ...this.state,
          loadingPerusahaan:true
        });
        axios
        .get(`${localStorage.getItem("APIROUTE")}/jghris/getListDataPerusahaan`)
        .then(async  result => {
            // console.log(result.data.listPerusahaan);
            await this.setState({
              ...this.state,
              loadingPerusahaan:false,
              dataPerusahaan:result.data.listPerusahaan
            });
        })
        .catch(error => {
          console.log(error);
          console.log(this.props);
        });
    }
    refreshData = () =>  {
        this.setState({
          ...this.state,
          loadingPerusahaan:true
        });
        axios
        .get(`${localStorage.getItem("APIROUTE")}/jghris/getListDataPerusahaan`)
        .then(async  result => {
            // console.log(result.data.listPerusahaan);
            await this.setState({
              ...this.state,
              loadingPerusahaan:false,
              dataPerusahaan:result.data.listPerusahaan
            });
        })
        .catch(error => {
          console.log(error);
          console.log(this.props);
        });
    }
    openModalAdd = () =>  {
        this.setState({
            ...this.state,
            prmModalAdd:true
        });
    }
    closeModalAdd = () =>  {
        this.setState({
          ...this.state,
          prmModalAdd: false,
          addNamaPerusahaan:null,
          addAlamatPerusahaan:null,
          addTelephonPerusahaan:null,
          prmButtonAdd:false
        });
    }
    submitModalAdd=()=>{
        const dataToSend = {
            NAMA:this.state.addNamaPerusahaan,
            ALAMAT:this.state.addAlamatPerusahaan,
            TELP:this.state.addTelephonPerusahaan
        };
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/addDataPerusahaan`, dataToSend, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(async result => {
            if(result.data.status === "00"){
                await this.setState({
                ...this.state,
                buttonLoginPrm:false,
                buttonLoginText:"Login"
                });
            } else {
                this.setState({
                ...this.state,
                buttonLoginPrm:false,
                buttonLoginText:"Login"
                });
                alert(result.data.message)
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    openModalEdit = (data) =>  {
        this.setState({
          ...this.state,
          prmModalEdit: true,
          editIdPerusahaan:data.id_perusahaan,
          editNamaPerusahaan:data.nama_perusahaan,
          editAlamatPerusahaan:data.alamat_perusahaan,
          editTelephonPerusahaan:data.telp_perusahaan,
          prmButtonEdit:false,
        });
    }
    closeModalEdit = async () =>  {
        await this.setState({
          ...this.state,
          prmModalEdit: false,
          editIdPerusahaan:null,
          editNamaPerusahaan:null,
          editAlamatPerusahaan:null,
          editTelephonPerusahaan:null,
          prmButtonEdit:false,
        });
    }
    handleChange = event =>  {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value
      });
    }
    render() {
        const DataButton = (data) => (
          <div>
            <button className="myBtn" onClick={()=> this.openModalEdit(data)}><i className="fa fa-edit fa-2x" aria-hidden="true"></i></button>
          </div>
        );
        const PerusahaanColumns = [
                {
                name: 'Id Perusahaan',
                selector: 'id_perusahaan',
                sortable: true,
                },
                {
                name: 'Nama Perusahaan',
                selector: 'nama_perusahaan',
                sortable: true,
                },
                {
                name: 'Alamat Perusahaan',
                selector: 'alamat_perusahaan',
                sortable: true,
                },
                {
                name: 'Telp Perusahaan',
                selector: 'telp_perusahaan',
                sortable: true,
                },
                {
                  name: 'Tool',
                  button: true,
                  cell: row => DataButton(row),
                }
        ];
        return (
            <div>
                <Modal isOpen={this.state.prmModalAdd} backdrop={"static"} size="xl">
                    <ModalHeader toggle={() => this.closeModalAdd()}>
                        Form Add Data Perusahaan
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{paddingTop:10}}>
                            <Col xs="12" sm="12" md="12">
                                <FormGroup>
                                    <Label for="addNamaPerusahaan">Nama Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="addNamaPerusahaan"
                                        id="addNamaPerusahaan"
                                        value={this.state.addNamaPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Nama Perusahaan"
                                        disabled={this.state.prmButtonAdd===true?true:false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addAlamatPerusahaan">Alamat Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="addAlamatPerusahaan"
                                        id="addAlamatPerusahaan"
                                        value={this.state.addAlamatPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Alamat Perusahaan"
                                        disabled={this.state.prmButtonAdd===true?true:false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="addTelephonPerusahaan">Telp Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="addTelephonPerusahaan"
                                        id="addTelephonPerusahaan"
                                        value={this.state.addTelephonPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Telp Perusahaan"
                                        disabled={this.state.prmButtonAdd===true?true:false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:10}}>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="danger" 
                                    onClick={() => this.closeModalAdd()}
                                    block={true}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="success" 
                                    onClick={() => this.setState({...this.state,prmButtonAdd:true})}
                                    block={true}
                                >
                                    <ScaleLoader
                                        height={18}
                                        width={4}
                                        radius={2}
                                        margin={2}
                                        color={'#FFFFFF'}
                                        loading={this.state.prmButtonAdd}
                                    />
                                    {this.state.prmButtonAdd===true?"":"Save"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.prmModalEdit} backdrop={"static"} size="xl">
                    <ModalHeader toggle={() => this.closeModalEdit()}>
                        Form Edit Data Perusahaan
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{paddingTop:10}}>
                            <Col xs="12" sm="12" md="12">
                                <FormGroup>
                                    <Label for="editNamaPerusahaan">Nama Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="editNamaPerusahaan"
                                        id="editNamaPerusahaan"
                                        value={this.state.editNamaPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Nama Perusahaan"
                                        disabled={this.state.prmButtonEdit===true?true:false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="editAlamatPerusahaan">Alamat Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="editAlamatPerusahaan"
                                        id="editAlamatPerusahaan"
                                        value={this.state.editAlamatPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Alamat Perusahaan"
                                        disabled={this.state.prmButtonEdit===true?true:false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="editTelephonPerusahaan">Telp Perusahaan</Label>
                                    <Input
                                        type="text"
                                        name="editTelephonPerusahaan"
                                        id="editTelephonPerusahaan"
                                        value={this.state.editTelephonPerusahaan}
                                        onChange={this.handleChange}
                                        placeholder="Input Telp Perusahaan"
                                        disabled={this.state.prmButtonEdit===true?true:false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:10}}>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="danger" 
                                    onClick={() => this.closeModalEdit()}
                                    block={true}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <Button 
                                    color="success" 
                                    onClick={() => this.setState({...this.state,prmButtonEdit:true})}
                                    block={true}
                                >
                                    <ScaleLoader
                                        height={18}
                                        width={4}
                                        radius={2}
                                        margin={2}
                                        color={'#FFFFFF'}
                                        loading={this.state.prmButtonEdit}
                                    />
                                    {this.state.prmButtonEdit===true?"":"Update"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-header">
                                    <Row>
                                        <Col xs="10" sm="10" md="10" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                        </Col>
                                        <Col xs="2" sm="2" md="2" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                            <button
                                                className="myBtn"
                                                style={{display:"flex",alignItems:"center",fontSize:25}}
                                                onClick={() => 
                                                    this.openModalAdd()
                                                }
                                            >
                                                <i className="fa fa-plus-square" aria-hidden="true"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body">
                                    <DataTableExtensions
                                        columns={PerusahaanColumns}
                                        data={this.state.dataPerusahaan}
                                        print={false}
                                        exportHeaders={false}
                                        export={false}
                                    >
                                    <DataTable
                                        defaultSortField="title"
                                        pagination={true}
                                        highlightOnHover={true}
                                        striped={false}
                                        progressPending={this.state.loadingPerusahaan}
                                        noHeader={true}
                                        fixedHeader={false}
                                        paginationPerPage={5}
                                        paginationRowsPerPageOptions={[5]}
                                        fixedHeaderScrollHeight="300px"
                                    />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
  
export default connect()(SetupPerusahaan);