import React, { Component } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class GeneratePayroll extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs="6" sm="6" md="3">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">Hello</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div style={{position:"fixed",right:15,bottom:15,width:250,height:250}}>
                    <ExcelFile 
                        element={
                            <button className="btn btn-outline-dark btn-lg btn-block">
                                {`Data`}
                            </button>
                        }
                        filename="nama baru"
                    >
                        <ExcelSheet data={dataSet1} name="Employees">
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Wallet Money" value="amount"/>
                            <ExcelColumn label="Gender" value="sex"/>
                            <ExcelColumn label="Marital Status" value={(col) => col.is_married ? "Married" : "Single"}/>
                        </ExcelSheet>
                        <ExcelSheet data={dataSet2} name="Leaves">
                            <ExcelColumn label="Name" value="name"/>
                            <ExcelColumn label="Total Leaves" value="total"/>
                            <ExcelColumn label="Remaining Leaves" value="remaining"/>
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
        );
    }
}

export default GeneratePayroll;