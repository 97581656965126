import { FaTachometerAlt, FaGem, FaList } from 'react-icons/fa';
const NavigasiList = [
    {
        "icon": <FaList />,
        "title": "Rekap Absensi",
        "path": "/reakapAbsensi",
        "submenu": [
        ]
    }
]

export default (NavigasiList);