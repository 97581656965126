import { combineReducers } from 'redux'

const initialState = {
  authToken:null,
  userinfo:null,
  prmModal:false,
  prmModalBody:"",
  idKBB:null,
};


const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "SETAUTHTOKEN":
        return { ...state, authToken: action.payload };
    case "SETUSERINFO":
        return { ...state, userinfo: action.payload };
    case "OPENMODAL":
        return { ...state, prmModal: true , prmModalBody: action.payload };
    case "CLOSEMODAL":
        return { ...state, prmModal: false , prmModalBody: action.payload };
    case "SETIDKBB":
        return { ...state, idKBB: action.payload };
    default:
      return state;
  }
};
export default combineReducers({
  reducer
});
