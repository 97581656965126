import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import {
    Row,
    Col,
    Collapse
} from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

const DatePicker = require("reactstrap-date-picker");

class BuatPerhitunganPayroll extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // base state
        dataPerhitunganPayroll: [],
        periodeAwal:null,
        periodeAkhir:null,
        prmLoading:false
        // base state --
      };
    }
    componentDidMount = () =>  {
      let startTime = moment(`${`2019-12-17`} ${`09:40:42`}`)
      let finishTime = moment(`${`2019-12-17`} ${`09:10:00`}`)
      let c = startTime.diff(finishTime, 'minutes')
    }
    handleChangePeriodeAwal = (value, formattedValue) => {
        this.setState({
            ...this.state,
            periodeAwal:formattedValue
        })
    }
    handleChangePeriodeAkhir = (value, formattedValue) => {
        this.setState({
            ...this.state,
            periodeAkhir:formattedValue
        })
    }
    BuatPerhitunganPayroll = () =>  {
        this.setState({
          ...this.state,
          prmLoading:true,
          buttonSave:""
        });
        const dataToSend = {
            STARTDATE:this.state.periodeAwal,
            ENDDATE:this.state.periodeAkhir
        };
        if(dataToSend.STARTDATE === null || dataToSend.ENDDATE === null){
            alert("Periode awal atau periode akhir tidak boleh kosong")
            this.setState({
                ...this.state,
                prmLoading:false
            });
        } else {
            axios
            .post(`${localStorage.getItem("APIROUTE")}/jghris/buatPerhitunganPayroll`, dataToSend, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(async result => {
                let hasilPerhitunganPayroll = await this.prosesHitungPotongan(result.data.dataFinal)
                console.log(hasilPerhitunganPayroll);
                this.setState({
                    ...this.state,
                    prmLoading:false,
                    dataPerhitunganPayroll:hasilPerhitunganPayroll,
                    formPerhitunganToggle:false
                });
            })
            .catch(error => {
                console.log(error);
                console.log(this.props);
            });
        }
    }
    prosesHitungPotongan = (data) =>  {
        let dataToProcess = data
        for(let i=0;i<dataToProcess.length;i++){
            let dataRealisasiRaw = dataToProcess[i].REALISASI
            let dataPotonganPayroll = []
            let NONE = 0
            let oke = 0
            let OFF = 0
            let IP = 0
            let IC = 0
            let C = 0
            let DINAS = 0
            let SD = 0
            let ST = 0
            let IPH = 0
            let IPS = 0
            let LA = 0
            let IG = 0
            let ALP = 0
            let TELATL0 = 0
            let TELATL30 = 0
            let TELATL60 = 0
            for(let i1=0;i1<dataRealisasiRaw.length;i1++){
                if(dataRealisasiRaw[i1].id_jadwal=="-"){
                    NONE++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:"NONE",statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="034"){
                    OFF++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="037"){
                    IP++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="033"){
                    DINAS++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="038"){
                    IC++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="032"){
                    C++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="031"){
                    SD++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="036"){
                    ST++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="039"){
                    IPS++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="040"){
                    IPH++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else if(dataRealisasiRaw[i1].id_jadwal=="035"){
                    IG++
                    dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                } else {
                    if(dataRealisasiRaw[i1].fingerIn == "-" && dataRealisasiRaw[i1].fingerOut == "-"){
                        ALP++
                        dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:"ALPA",statusAbsensi:false}
                    } else if(dataRealisasiRaw[i1].fingerIn == "-" || dataRealisasiRaw[i1].fingerOut == "-"){
                        LA++
                        dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:"LUPA ABSEN",statusAbsensi:false}
                    } else {
                        let startTime = moment(`${dataRealisasiRaw[i1].prmFingerIn}`)
                        let finishTime = moment(`${dataRealisasiRaw[i1].tanggal} ${dataRealisasiRaw[i1].starttime}`)
                        let prmTelat = parseInt(startTime.diff(finishTime, 'minutes'))
                        if(prmTelat>0){
                            if(prmTelat > 0 && prmTelat <= 30 ){
                                TELATL0++
                                dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:false}
                            } else if(prmTelat > 30 && prmTelat <= 60 ){
                                TELATL30++
                                dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:false}
                            } else if(prmTelat > 60 ){
                                TELATL60++
                                dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:false}
                            }
                        } else {
                            oke++
                            dataToProcess[i].REALISASI[i1] = {...dataToProcess[i].REALISASI[i1],jadwalToShow:dataToProcess[i].REALISASI[i1].nama_jadwal,statusAbsensi:true}
                        }
                    }
                }
            }
            let prmJumlahHariKerjaHarian = TELATL0+TELATL30+TELATL60+LA+oke+IPH+IPS+IP+DINAS
            let JumlahHariKerja = dataRealisasiRaw.length
            let totalHariKerjaNormal = parseInt(oke+OFF+IP+IC+C+DINAS+SD+ST+IPH+IPS+LA+IG+ALP+TELATL0+TELATL30+TELATL60)
            let totalHariKerja = parseInt(dataRealisasiRaw.length)-NONE-IG-ALP
            let prmInsentifKehadiran = TELATL0+TELATL30+TELATL60+LA+ST+SD+IG+ALP+IPH+IPS+IC
            let tunjanganHarianBulanan = dataToProcess[i].Tunjangan_Harian_per_bulan == 0 && dataToProcess[i].Gapok == 0 ?parseInt(prmJumlahHariKerjaHarian)*parseInt(dataToProcess[i].Tunjangan_Harian_per_hari):parseInt(dataToProcess[i].Tunjangan_Harian_per_bulan)
            let insentifKehadiran = dataToProcess[i].PosisiToShow.includes('STAFF') || dataToProcess[i].PosisiToShow.includes('staff') ? prmInsentifKehadiran > 0 ? '0' : '0' : '0'
            let insentifKehadiranNote = []
            TELATL0+TELATL30+TELATL60 > 0 && insentifKehadiranNote.push(`ADA TELAT ${TELATL0+TELATL30+TELATL60}X`)
            LA > 0 && insentifKehadiranNote.push(`ADA LUPA ABSEN ${LA}X`)
            ST+SD > 0 && insentifKehadiranNote.push(`ADA SAKIT ${ST+SD}X`)
            IG+IPH+IPS+IC > 0 && insentifKehadiranNote.push(`ADA IZIN ${IG+IPH+IPS+IC}X`)
            ALP > 0 && insentifKehadiranNote.push(`ADA ALPA ${ALP}X`)
            let noteTambahanLainLain = []
            for(let itam1=0;itam1<dataToProcess[i].dataTambahan.length;itam1++){
                noteTambahanLainLain.push(`${dataToProcess[i].dataTambahan[itam1].ket_tambahan}`)
            }
            let notePotonganAbsensi = []
            TELATL0+TELATL30+TELATL60 > 0 && notePotonganAbsensi.push(`ADA TELAT ${TELATL0+TELATL30+TELATL60}X`)
            LA > 0 && notePotonganAbsensi.push(`ADA LUPA ABSEN ${LA}X`)
            ST > 0 && notePotonganAbsensi.push(`ADA SAKIT TANPA SURAT ${ST}X`)
            IG > 0 && notePotonganAbsensi.push(`ADA IZIN POTONG GAJI ${IG}X`)
            IPH > 0 && notePotonganAbsensi.push(`ADA IZIN POTONG INSENTIF HARIAN ${IPH}X`)
            IPS > 0 && notePotonganAbsensi.push(`ADA IZIN POTONG SETENGAH INSENTIF HARIAN ${IPS}X`)
            ALP > 0 && notePotonganAbsensi.push(`ADA ALPA ${ALP}X`)
            let notePotonganLainLain = []
            let prmTHP = parseInt(dataToProcess[i].Gapok)+parseInt(tunjanganHarianBulanan)+parseInt(dataToProcess[i].tunjangan_jabatan)+parseInt(dataToProcess[i].Tunjangan_Lain)
            let takeHomePay = dataToProcess[i].Tunjangan_Harian_per_bulan == 0 && dataToProcess[i].Gapok == 0 ? prmTHP : dataToProcess[i].statusPayroll == 'New Employee' || dataToProcess[i].statusPayroll == 'Resign' || dataToProcess[i].statusPayroll == 'Prorate' ? prmTHP/parseInt(dataRealisasiRaw.length)*parseInt(totalHariKerja) : prmTHP/parseInt(dataRealisasiRaw.length)*parseInt(totalHariKerjaNormal)
            for(let ipot1=0;ipot1<dataToProcess[i].dataPotongan.length;ipot1++){
                notePotonganLainLain.push(`${dataToProcess[i].dataPotongan[ipot1].ket_potongan}`)
            }
            let potonganGajiPokok = dataToProcess[i].Tunjangan_Harian_per_bulan == 0 && dataToProcess[i].Gapok == 0 ? 0 : dataToProcess[i].statusPayroll == 'New Employee' || dataToProcess[i].statusPayroll == 'Resign' || dataToProcess[i].statusPayroll == 'Prorate' ? 0 : dataToProcess[i].Gapok/parseInt(dataRealisasiRaw.length)*parseInt(IG+ALP)
            let prmPotonganHarian = parseInt(TELATL30) + parseInt(TELATL60) + parseInt(LA) + parseInt(ST) + parseInt(IPH)
            let prmPotonganSetengahHarian = parseInt(TELATL0) + parseInt(IPS)
            let totalPotonganHarian = dataToProcess[i].Tunjangan_Harian_per_bulan == 0 && dataToProcess[i].Gapok == 0 ? 0 : parseInt(dataToProcess[i].Tunjangan_Harian_per_bulan)/parseInt(JumlahHariKerja)*parseInt(prmPotonganHarian)
            let totalPotonganSetengahHarian = dataToProcess[i].Tunjangan_Harian_per_bulan == 0 && dataToProcess[i].Gapok == 0 ? 0 : (parseInt(dataToProcess[i].Tunjangan_Harian_per_bulan)/parseInt(JumlahHariKerja)*parseInt(prmPotonganSetengahHarian))/2
            let potonganInsentifHarian = parseInt(totalPotonganHarian) + parseInt(totalPotonganSetengahHarian)
            let totalPotonganAbsensi = parseInt(potonganGajiPokok) + parseInt(potonganInsentifHarian)
            let totalTambahanPayroll = parseInt(insentifKehadiran) + parseInt(dataToProcess[i].totalLembur) + parseInt(dataToProcess[i].totalTambahan)
            let totalPotonganPayroll = parseInt(totalPotonganAbsensi) + parseInt(dataToProcess[i].pph) + parseInt(dataToProcess[i].BPJS_KESEHATAN) + parseInt(dataToProcess[i].BPJS_KETENAGAKERJAAN) + parseInt(dataToProcess[i].totalPotongan)
            let totalGajiDiterima = parseInt(takeHomePay) + parseInt(totalTambahanPayroll) - parseInt(totalPotonganPayroll)
            let parameterHead = dataToProcess[i].Gapok
            dataPotonganPayroll.push({
                none:NONE,
                oke:oke,
                off:OFF,
                ip:IP,
                ic:IC,
                c:C,
                dinas:DINAS,
                sd:SD,
                st:ST,
                iph:IPH,
                ips:IPS,
                la:LA,
                ig:IG,
                alp:ALP,
                telatl0:TELATL0,
                telatl30:TELATL30,
                telatl60:TELATL60,
                prmJumlahHariKerjaHarian:prmJumlahHariKerjaHarian,
                JumlahHariKerja:JumlahHariKerja,
                totalHariKerja:totalHariKerja,
                prmInsentifKehadiran:prmInsentifKehadiran,
                tunjanganHarianBulanan:tunjanganHarianBulanan,
                insentifKehadiran:insentifKehadiran,
                insentifKehadiranNote:insentifKehadiranNote,
                noteTambahanLainLain:noteTambahanLainLain,
                notePotonganAbsensi:notePotonganAbsensi,
                notePotonganLainLain:notePotonganLainLain,
                prmTHP:prmTHP,
                takeHomePay:takeHomePay,
                potonganGajiPokok:potonganGajiPokok,
                prmPotonganHarian:prmPotonganHarian,
                prmPotonganSetengahHarian:prmPotonganSetengahHarian,
                totalPotonganHarian:totalPotonganHarian,
                totalPotonganSetengahHarian:totalPotonganSetengahHarian,
                potonganInsentifHarian:potonganInsentifHarian,
                totalTambahanPayroll:totalTambahanPayroll,
                totalPotonganPayroll:totalPotonganPayroll,
                totalGajiDiterima:totalGajiDiterima,
                totalPotonganAbsensi:totalPotonganAbsensi
            })
            dataToProcess[i] = {
                ...dataToProcess[i],
                dataPotonganPayroll:dataPotonganPayroll,
                none:NONE,
                oke:oke,
                off:OFF,
                ip:IP,
                ic:IC,
                c:C,
                dinas:DINAS,
                sd:SD,
                st:ST,
                iph:IPH,
                ips:IPS,
                la:LA,
                ig:IG,
                alp:ALP,
                telatl0:TELATL0,
                telatl30:TELATL30,
                telatl60:TELATL60,
                prmJumlahHariKerjaHarian:prmJumlahHariKerjaHarian,
                JumlahHariKerja:JumlahHariKerja,
                totalHariKerja:totalHariKerja,
                prmInsentifKehadiran:prmInsentifKehadiran,
                tunjanganHarianBulanan:tunjanganHarianBulanan,
                insentifKehadiran:insentifKehadiran,
                insentifKehadiranNote:insentifKehadiranNote.toString(),
                noteTambahanLainLain:noteTambahanLainLain.toString(),
                notePotonganAbsensi:notePotonganAbsensi.toString(),
                notePotonganLainLain:notePotonganLainLain.toString(),
                prmTHP:prmTHP,
                takeHomePay:takeHomePay,
                potonganGajiPokok:potonganGajiPokok,
                prmPotonganHarian:prmPotonganHarian,
                prmPotonganSetengahHarian:prmPotonganSetengahHarian,
                totalPotonganHarian:totalPotonganHarian,
                totalPotonganSetengahHarian:totalPotonganSetengahHarian,
                potonganInsentifHarian:potonganInsentifHarian,
                totalTambahanPayroll:totalTambahanPayroll,
                totalPotonganPayroll:totalPotonganPayroll,
                totalGajiDiterima:totalGajiDiterima,
                totalPotonganAbsensi:totalPotonganAbsensi
            }

        }
        return dataToProcess
    }
    formatNumber = (num) =>  {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    buatKBBB = async () =>  {
        this.setState({
          ...this.state,
          prmLoading:true
        });
        let prmDataKBB = await this.cekDataKBB(this.state.dataPerhitunganPayroll)
        if(prmDataKBB=="NOT PASSED"){
            alert("Tidak dapat memproses data perhitungan payroll, karena terdapat gaji minus. mohon cek kembali realisasi beserta finger")
            this.setState({
                ...this.state,
                prmLoading:false
            });
        } else {
            const dataToSend = {
                STARTDATE:this.state.periodeAwal,
                ENDDATE:this.state.periodeAkhir,
                DATATOKBB:this.state.dataPerhitunganPayroll,
                USER:this.props.userinfo.NIK
            };
            axios
            .post(`${localStorage.getItem("APIROUTE")}/jghris/addKBB`, dataToSend, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(result => {
                if(result.data.status == '01'){
                    this.setState({
                        ...this.state,
                        dataPerhitunganPayroll: [],
                        periodeAwal:null,
                        periodeAkhir:null,
                        prmLoading:false
                    });
                    this.props.history.push({
                        pathname: "/DataKBB",
                        search: `?ID=${result.data.ID}`
                    })
                }
            })
            .catch(error => {
                console.log(error);
                console.log(this.props);
            });
        }
    }
    cekDataKBB = (data) =>  {
        let prmDataHasilCek = 0
        for(let i=0;i<data.length;i++){
            let prmHead = (data[i].Gapok == ''||data[i].Gapok == '-'||data[i].Gapok == '0') && (data[i].tunjanganHarianBulanan == ''||data[i].tunjanganHarianBulanan == '-'||data[i].tunjanganHarianBulanan == '0') && (data[i].tunjangan_jabatan == ''||data[i].tunjangan_jabatan == '-'||data[i].tunjangan_jabatan == '0') && (data[i].Tunjangan_Lain == ''||data[i].Tunjangan_Lain == '-'||data[i].Tunjangan_Lain == '0') ? 'HEAD' : 'STAFF'
            parseFloat(data[i].totalGajiDiterima) < 0 && prmHead == "STAFF" && prmDataHasilCek++
        }
        if(prmDataHasilCek>0){
            return "NOT PASSED"
        } else {
            return "PASSED"
        }
    }
    render() {
        return (
            <div>
                <div className="overlayMask" style={{visibility:this.state.prmLoading===true?"visible":"hidden"}}>
                <ScaleLoader
                    height={90}
                    width={20}
                    radius={10}
                    margin={10}
                    color={'#ffffff'}
                />
                </div>
                <div style={{padding:"10px",position:"sticky",top:0,zIndex:99,backgroundColor:"#f8f9fa"}}>
                    <Collapse isOpen={this.state.dataPerhitunganPayroll.length > 0?false:true}>
                        <div className="card" style={{width:"100%"}}>
                            <div className="card-body" style={{padding:10}}>
                                <Row style={{paddingTop:5,paddingBottom:5}}>
                                    <Col>
                                        <Row>
                                            <Col xs="6" sm="6" md="6"><span>Periode Awal Payroll</span></Col>
                                            <Col xs="6" sm="6" md="6"><span>Periode Akhir Payroll</span></Col>
                                        </Row>
                                        <Row>
                                            <Col xs="6" sm="6" md="6">
                                                <DatePicker id = "periodeAwal" 
                                                    value   = {this.state.periodeAwal}
                                                    dateFormat = {`YYYY-MM-DD`}
                                                    onChange= {(v,f) => this.handleChangePeriodeAwal(v, f)}
                                                />
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <DatePicker id = "example-periodeAkhir" 
                                                    value   = {this.state.periodeAkhir}
                                                    dateFormat = {`YYYY-MM-DD`}
                                                    onChange= {(v,f) => this.handleChangePeriodeAkhir(v, f)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop:5,paddingBottom:5}}>
                                    <Col>
                                        <button className="btn btn-outline-dark btn-lg btn-block" onClick={() => this.BuatPerhitunganPayroll()}>
                                            {`Buat Perhitungan`}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Collapse>
                    <Collapse isOpen={this.state.dataPerhitunganPayroll.length > 0?true:false}>
                        <div style={{padding:"10px",position:"sticky",top:0,zIndex:99}}>
                            <ExcelFile 
                                element={
                                    <button className="btn btn-secondary">
                                        <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                        <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data Perhitungan ini</span>
                                    </button>
                                }
                                filename="perhitungan payroll"
                            >
                                <ExcelSheet data={this.state.dataPerhitunganPayroll} name="Data Payroll">
                                    <ExcelColumn label="NIK" value="NIK"/>
                                    <ExcelColumn label="NAMA" value="NAMA"/>
                                    <ExcelColumn label="JABATAN" value="PosisiToShow"/>
                                    <ExcelColumn label="LOKASI" value="StoreToShow"/>
                                    <ExcelColumn label="STATUS" value="Level"/>
                                    <ExcelColumn label="STATUS PENGGAJIAN" value="statusPayroll"/>
                                    <ExcelColumn label="GAJI POKOK" value="Gapok"/>
                                    <ExcelColumn label="TUNJANGAN HARIAN" value="tunjanganHarianBulanan"/>
                                    <ExcelColumn label="TUNJANGAN JABATAN" value="tunjangan_jabatan"/>
                                    <ExcelColumn label="TUNJANGAN LAIN0LAIN" value="Tunjangan_Lain"/>
                                    <ExcelColumn label="TAKE HOME PAY" value="takeHomePay"/>
                                    <ExcelColumn label="INSENTIF KEHADIRAN" value="insentifKehadiran"/>
                                    <ExcelColumn label="NOTE*" value="insentifKehadiranNote"/>
                                    <ExcelColumn label="LEMBUR" value="totalLembur"/>
                                    <ExcelColumn label="TAMBAHAN LAIN0LAIN" value="totalTambahan"/>
                                    <ExcelColumn label="NOTE**" value="noteTambahanLainLain"/>
                                    <ExcelColumn label="TOTAL TAMBAHAN" value="totalTambahanPayroll"/>
                                    <ExcelColumn label="POTONGAN GAJI POKOK" value="potonganGajiPokok"/>
                                    <ExcelColumn label="POTONGAN TUNJANGAN HARIAN" value="potonganInsentifHarian"/>
                                    <ExcelColumn label="TOTAL POTONGAN ABSENSI" value="totalPotonganAbsensi"/>
                                    <ExcelColumn label="NOTE***" value="notePotonganAbsensi"/>
                                    <ExcelColumn label="PPH21" value="pph"/>
                                    <ExcelColumn label="BPJS KESEHATAN" value="BPJS_KESEHATAN"/>
                                    <ExcelColumn label="BPJS KETENAGAKERJAAN" value="BPJS_KETENAGAKERJAAN"/>
                                    <ExcelColumn label="POTONGAN LAIN LAIN" value="totalPotongan"/>
                                    <ExcelColumn label="NOTE****" value="notePotonganLainLain"/>
                                    <ExcelColumn label="TOTAL POTONGAN" value="totalPotonganPayroll"/>
                                    <ExcelColumn label="TOTAL GAJI DITERIMA" value="totalGajiDiterima"/>
                                    <ExcelColumn label="NO REKENING" value="No_Rekening"/>
                                    <ExcelColumn label="TELAT 1 > 30" value="telatl0"/>
                                    <ExcelColumn label="TELAT > 30" value="telatl30"/>
                                    <ExcelColumn label="TELAT > 60" value="telatl60"/>
                                    <ExcelColumn label="LA" value="la"/>
                                    <ExcelColumn label="ST" value="st"/>
                                    <ExcelColumn label="IG" value="ig"/>
                                    <ExcelColumn label="ALP" value="alp"/>
                                    <ExcelColumn label="SD" value="sd"/>
                                    <ExcelColumn label="IPH" value="iph"/>
                                    <ExcelColumn label="IPS" value="ips"/>
                                    <ExcelColumn label="IC" value="ic"/>
                                    <ExcelColumn label="C" value="c"/>
                                    <ExcelColumn label="IP" value="ip"/>
                                    <ExcelColumn label="OFF" value="off"/>
                                    <ExcelColumn label="DINAS" value="dinas"/>
                                    <ExcelColumn label="FINGERIN" value="oke"/>
                                    <ExcelColumn label="NONE" value="none"/>
                                    <ExcelColumn label="JK" value="JumlahHariKerja"/>
                                    <ExcelColumn label="prm kehadiran" value="totalHariKerja"/>
                                    <ExcelColumn label="company" value="perusahaan"/>
                                </ExcelSheet>
                            </ExcelFile>
                            <button className="btn btn-secondary" onClick={() => this.buatKBBB()}>
                                <i class="fa fa-cogs" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Proses Perhitungan ini</span>
                            </button>
                            <button className="btn btn-secondary" onClick={() => this.setState({...this.state,dataPerhitunganPayroll:[],periodeAwal:null,periodeAkhir:null})}>
                                <i class="fa fa-refresh" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Hitung Ulang</span>
                            </button>
                        </div>
                    </Collapse>
                </div>
                <div>
                    <Row>
                        <Col xs="12" sm="12" md="12">
                            {this.state.dataPerhitunganPayroll.length > 0 && this.state.dataPerhitunganPayroll.map((dataPayroll,index) =>
                            <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}} key={index}>
                                <div className="card" style={{width:"100%"}}>
                                    <div className="card-body">
                                        <Row style={{paddingTop:10,paddingBottom:5}}>
                                            <Col xs="12" sm="12" md="12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Detail Karyawan</span>
                                                    </div>
                                                    <div className="card-body" style={{paddingTop:10,paddingBottom:10}}>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>NIK Karyawan</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.NIK}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Nama Karyawan</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.NAMA}`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Jabatan Karyawan</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.PosisiToShow}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Status Karyawan</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.Level}`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Lokasi Kerja</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.StoreToShow}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Nomor Rek BCA</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${dataPayroll.No_Rekening}`}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="row equal-cols">
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Detail Gaji</span>
                                                    </div>
                                                    <div className="card-body" style={{paddingTop:10,paddingBottom:10}}>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Gaji Pokok</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.Gapok))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Tunjangan Harian</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.Tunjangan_Harian_per_bulan))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Tunjangan Jabatan</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.tunjangan_jabatan))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Tunjangan Lain-Lain</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.Tunjangan_Lain))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>TOTAL GAJI</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.Gapok)+parseInt(dataPayroll.Tunjangan_Harian_per_bulan)+parseInt(dataPayroll.tunjangan_jabatan)+parseInt(dataPayroll.Tunjangan_Lain))}`}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Detail Tambahan</span>
                                                    </div>
                                                    <div className="card-body" style={{paddingTop:10,paddingBottom:10}}>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Insentif Kehadiran</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Lembur</span>
                                                                </div>
                                                                <div>
                                                                <span>{`${this.formatNumber(parseInt(dataPayroll.totalLembur))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Lain-Lain</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.totalTambahan))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>TOTAL TAMBAHAN</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Detail Potongan</span>
                                                    </div>
                                                    <div className="card-body" style={{paddingTop:10,paddingBottom:10}}>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Absensi</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Pajak (PPH 21)</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>BPJS Ketenagakerjaan</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>BPJS Kesehatan</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>Lain-Lain</span>
                                                                </div>
                                                                <div>
                                                                    <span>{`${this.formatNumber(parseInt(dataPayroll.totalPotongan))}`}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontWeight:"bold"}}>TOTAL POTONGAN</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Row style={{paddingTop:5,paddingBottom:5}}>
                                            <Col xs="12" sm="12" md="12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Rekap Kehadiaran</span>
                                                    </div>
                                                    <div className="card-body" style={{padding:0}}>
                                                        <Row>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt",color:parseInt(dataPayroll.dataPotonganPayroll[0].telatl0)>0?"#FF0000":"#000000"}}>{dataPayroll.dataPotonganPayroll[0].telatl0}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Telat 0 > 30`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt",color:parseInt(dataPayroll.dataPotonganPayroll[0].telatl30)>0?"#FF0000":"#000000"}}>{dataPayroll.dataPotonganPayroll[0].telatl30}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Telat 30 > 60`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt",color:parseInt(dataPayroll.dataPotonganPayroll[0].telatl60)>0?"#FF0000":"#000000"}}>{dataPayroll.dataPotonganPayroll[0].telatl60}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Telat > 60`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt",color:parseInt(dataPayroll.dataPotonganPayroll[0].la)>0?"#FF0000":"#000000"}}>{dataPayroll.dataPotonganPayroll[0].la}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Lupa Absen`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt",color:parseInt(dataPayroll.dataPotonganPayroll[0].alp)>0?"#FF0000":"#000000"}}>{dataPayroll.dataPotonganPayroll[0].alp}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Alpa`}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].iph}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Izin Potong Harian`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].ips}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Izin Potong Setengah Harian`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].ig}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Izin Potong Gaji`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].sd}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Sakit Dengan Surat`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].st}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Sakit Tanpa Surat`}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].ip}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Izin Upah Penuh`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{parseInt(dataPayroll.dataPotonganPayroll[0].ic)+parseInt(dataPayroll.dataPotonganPayroll[0].c)}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Cuti`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].dinas}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Dinas`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].oke}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Day In`}</span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span style={{fontWeight:"bold",fontSize:"24pt"}}>{dataPayroll.dataPotonganPayroll[0].off}</span>
                                                                </div>
                                                                <div className="detailKaryawanAlignCenter">
                                                                    <span>{` Off`}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:5,paddingBottom:10}}>
                                            <Col xs="12" sm="12" md="12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <span style={{fontWeight:"bold"}}>Detail Kehadiaran</span>
                                                    </div>
                                                    <div className="card-body" style={{padding:0}}>
                                                        <Row style={{paddingTop:5,paddingBottom:10}}>
                                                            <Col xs="12" sm="12" md="12" className="detailKaryawanAlignCenter">
                                                                <div style={{width:"70vw",overflow:"scroll",overflowX:"scroll",overflowY:"hidden"}}>
                                                                    <table>
                                                                        <tr>
                                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                                            <td key={index} style={{border:"1px solid #000"}} key={index2}>
                                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.tanggal}</span>
                                                                            </div>
                                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{moment(dataRealisasi.tanggal).format('ddd')}</span>
                                                                            </div>
                                                                            </td>
                                                                            )}
                                                                        </tr>
                                                                        <tr>
                                                                            {dataPayroll.REALISASI.length > 0 && dataPayroll.REALISASI.map((dataRealisasi,index2) =>
                                                                            <td key={index} style={{backgroundColor:dataRealisasi.statusAbsensi==false?"#ffd1d1":"#ffffff",border:"1px solid #000"}} key={index2}>
                                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.jadwalToShow}</span>
                                                                            </div>
                                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.fingerIn.substring(0, 5)}</span>
                                                                            </div>
                                                                            <div style={{width:"5vw",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                                                <span style={{fontWeight:"bold",textAlign:"center"}}>{dataRealisasi.fingerOut.substring(0, 5)}</span>
                                                                            </div>
                                                                            </td>
                                                                            )}
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      authToken: state.reducer.authToken,
      userinfo: state.reducer.userinfo
    };
  };
  
  export default withRouter(connect(mapStateToProps)(BuatPerhitunganPayroll));