import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col
} from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import classnames from 'classnames';
import queryString from 'query-string';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class dataKbb extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // base state
        dataKBBH: [],
        dataKBBD: [],
        dataKBBTRANSFER: [],
        dataKBBTRANSFERSCU: [],
        dataKBBTRANSFERJAYGEE: [],
        dataKBBCASHSCU: [],
        dataKBBCASHJAYGEE: [],
        prmLoading:false,
        activeTab:'1'
        // base state --
      };
    }
    componentDidMount = () =>  {
        let url = this.props.location.search;
        let params = queryString.parse(url);
        this.setState({
          ...this.state,
          prmLoading:true
        });
        const dataToSend = {
            ID:params.ID
        };
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/getKbb`, dataToSend, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(result => {
            console.log(result.data);
            this.setState({
                ...this.state,
                dataKBBH: result.data.dataKBBH[0],
                dataKBBD: result.data.dataKBBD,
                dataKBBTRANSFER: result.data.dataKBBTRANSFER,
                dataKBBTRANSFERSCU: result.data.dataKBBTRANSFERSCU,
                dataKBBTRANSFERJAYGEE: result.data.dataKBBTRANSFERJAYGEE,
                dataKBBCASHSCU: result.data.dataKBBCASHSCU,
                dataKBBCASHJAYGEE: result.data.dataKBBCASHJAYGEE,
                prmLoading:false
            });
        })
        .catch(error => {
            console.log(error);
            console.log(this.props);
        });
    }
    toPage = (x) =>  {
        this.setState({
          ...this.state,
          activeTab:x,
        });
    }
    formatNumber = (num) =>  {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    toggleStatusPenggajian = (data) =>{
        let newData = this.state.dataKBBD
        newData[data].status_cara_penggajian = newData[data].status_cara_penggajian == "TRANSFER" ? "CASH" : "TRANSFER"
        this.setState({
            ...this.state,
            dataKBBD: newData
        });
    }
    actionBatal = () => {
        this.props.history.push({
            pathname: "/ListKBB"
        })
    }
    actionUpdate = () => {
        this.setState({
          ...this.state,
          prmLoading:true
        });
        const dataToSend = {
            ID:this.state.dataKBBH.id_kbb,
            DATA:this.state.dataKBBD
        };
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/updatekbb`, dataToSend, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(result => {
            alert(result.data.messages)
            this.setState({
                ...this.state,
                prmLoading:false
            });
        })
        .catch(error => {
            console.log(error);
            console.log(this.props);
        });
    }
    actionHapus = () => {
        this.setState({
          ...this.state,
          prmLoading:true
        });
        const dataToSend = {
            ID:this.state.dataKBBH.id_kbb
        };
        axios
        .post(`${localStorage.getItem("APIROUTE")}/jghris/cancelKBB`, dataToSend, {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then( async result => {
            alert(result.data.messages)
            await this.setState({
                ...this.state,
                prmLoading:false
            });
            this.props.history.push({
                pathname: "/ListKBB"
            })
        })
        .catch(error => {
            console.log(error);
            console.log(this.props);
        });
    }
    render() {
        return (
            <div>
                <div className="overlayMask" style={{visibility:this.state.prmLoading===true?"visible":"hidden"}}>
                <ScaleLoader
                    height={90}
                    width={20}
                    radius={10}
                    margin={10}
                    color={'#ffffff'}
                />
                </div>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",padding:"10px"}}>
                            <div className="card" style={{width:"100%"}}>
                                <div className="card-body">
                                    <Row style={{paddingTop:5,paddingBottom:5}}>
                                        <Col xs="12" sm="12" md="6">
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Nomor KBB</span>
                                            </div>
                                            <div>
                                                <span>{this.state.dataKBBH.nomor_kbb}</span>
                                            </div>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Periode KBB</span>
                                            </div>
                                            <div>
                                                <span>{`${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}</span>
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="12" md="6">
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Tanggal Pembuatan KBB</span>
                                            </div>
                                            <div>
                                                <span>{this.state.dataKBBH.tanggal_buat_kbb}</span>
                                            </div>
                                            <div>
                                                <span style={{fontWeight:"bold"}}>Status KBB</span>
                                            </div>
                                            <div>
                                                <span>{this.state.dataKBBH.status_kbb}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.props.userinfo.previlege=="ADMIN" || this.props.userinfo.previlege=="SPV-HR"?
                                    <div>
                                        <Row style={{paddingTop:5,paddingBottom:5}}>
                                            <Col
                                                className="detailKaryawanAlignCenter"
                                                style={{
                                                    backgroundColor:this.state.activeTab=='1'?"#e5e5e5":"#F7F7F7",
                                                    cursor:"pointer",
                                                    padding:20
                                                }}
                                                onClick={()=> this.toPage('1')}
                                            >
                                                <div>
                                                    <span style={{fontWeight:"bold"}}>Daftar KBB Transfer</span>
                                                </div>
                                            </Col>
                                            <Col
                                                className="detailKaryawanAlignCenter"
                                                style={{
                                                    backgroundColor:this.state.activeTab=='2'?"#e5e5e5":"#F7F7F7",
                                                    cursor:"pointer",
                                                    padding:20
                                                }}
                                                onClick={()=> this.toPage('2')}
                                            >
                                                <div>
                                                    <span style={{fontWeight:"bold"}}>Daftar KBB Cash</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:5,paddingBottom:5}}>
                                            <Col>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        <div style={{paddingRight:"15px"}}>
                                                            <Row>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Date</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Name</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Account No</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Amount</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Dept</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Number</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Tool</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="rowDataWraperv1">
                                                            <Row>
                                                                <Col>
                                                                {this.state.dataKBBD.length > 0 && this.state.dataKBBD.map((dataKBBD,index) =>
                                                                dataKBBD.status_cara_penggajian == "TRANSFER"&&
                                                                    <Row key={index}>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Transf. Date</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NAMA}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NO_REKENING}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignRight dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{this.formatNumber(dataKBBD.TOTAL_GAJI_DITERIMA)}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Dept</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NIK}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0,cursor:"pointer"}}>
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}} onClick={()=> this.toggleStatusPenggajian(index)}>
                                                                                <span>Pindah ke Daftar Cash <i className="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <div style={{paddingRight:"15px"}}>
                                                            <Row>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Date</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Name</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Account No</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Amount</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Dept</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Number</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Tool</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="rowDataWraperv1">
                                                            <Row>
                                                                <Col>
                                                                {this.state.dataKBBD.length > 0 && this.state.dataKBBD.map((dataKBBD,index) =>
                                                                dataKBBD.status_cara_penggajian != "TRANSFER"&&
                                                                    <Row key={index}>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Transf. Date</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NAMA}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NO_REKENING}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignRight dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{this.formatNumber(dataKBBD.TOTAL_GAJI_DITERIMA)}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Dept</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NIK}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0,cursor:"pointer"}}>
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}} onClick={()=> this.toggleStatusPenggajian(index)}>
                                                                                <span>Pindah ke Daftar Transfer <i className="fa fa-arrow-left" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:15,paddingBottom:5}}>
                                            <Col>
                                                <button className="btn btn-warning btn-block" onClick={()=> this.actionBatal()}>
                                                    <i class="fa fa-ban" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                    <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Kembali ke list KBB</span>
                                                </button>
                                            </Col>
                                            <Col>
                                                <button className="btn btn-success btn-block" onClick={()=> this.actionUpdate()}>
                                                    <i class="fa fa-upload" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                    <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Update</span>
                                                </button>
                                            </Col>
                                            <Col>
                                                <button className="btn btn-danger btn-block" onClick={() => { if (window.confirm('Apakah anda yakin akan menghapus data KBB ini ?')) this.actionHapus() } }>
                                                    <i class="fa fa-trash" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                    <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Hapus</span>
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:15,paddingBottom:5}}>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data KBB PT SCU</span>
                                                        </button>
                                                    }
                                                    filename={`Data KBB PT SCU ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBTRANSFERSCU} name={`Data KBB PT SCU`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data KBB PT JAYGEE</span>
                                                        </button>
                                                    }
                                                    filename={`Data KBB PT JAYGEE ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBTRANSFERJAYGEE} name={`Data KBB PT JAYGEE`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:15,paddingBottom:5}}>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data CASH PT SCU</span>
                                                        </button>
                                                    }
                                                    filename={`Data CASH PT SCU ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBCASHSCU} name={`Data CASH PT SCU`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data CASH PT JAYGEE</span>
                                                        </button>
                                                    }
                                                    filename={`Data CASH PT JAYGEE ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBCASHJAYGEE} name={`Data CASH PT JAYGEE`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                        </Row>
                                    </div>
                                        :""
                                    }
                                    {this.props.userinfo.previlege=="ADMIN" || this.props.userinfo.previlege=="CFO"?
                                    <div>
                                        <Row style={{paddingTop:5,paddingBottom:5}}>
                                            <Col
                                                className="detailKaryawanAlignCenter"
                                                style={{
                                                    backgroundColor:this.state.activeTab=='1'?"#e5e5e5":"#F7F7F7",
                                                    cursor:"pointer",
                                                    padding:20
                                                }}
                                                onClick={()=> this.toPage('1')}
                                            >
                                                <div>
                                                    <span style={{fontWeight:"bold"}}>Daftar KBB Transfer</span>
                                                </div>
                                            </Col>
                                            <Col
                                                className="detailKaryawanAlignCenter"
                                                style={{
                                                    backgroundColor:this.state.activeTab=='2'?"#e5e5e5":"#F7F7F7",
                                                    cursor:"pointer",
                                                    padding:20
                                                }}
                                                onClick={()=> this.toPage('2')}
                                            >
                                                <div>
                                                    <span style={{fontWeight:"bold"}}>Daftar KBB Cash</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:5,paddingBottom:5}}>
                                            <Col>
                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        <div style={{paddingRight:"15px"}}>
                                                            <Row>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Date</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="4" sm="4" md="4" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Name</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Account No</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Amount</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Dept</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Number</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="rowDataWraperv1">
                                                            <Row>
                                                                <Col>
                                                                {this.state.dataKBBD.length > 0 && this.state.dataKBBD.map((dataKBBD,index) =>
                                                                dataKBBD.status_cara_penggajian == "TRANSFER"&&
                                                                    <Row key={index}>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Transf. Date</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="4" sm="4" md="4" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NAMA}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NO_REKENING}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignRight dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{this.formatNumber(dataKBBD.TOTAL_GAJI_DITERIMA)}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Dept</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NIK}</span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <div style={{paddingRight:"15px"}}>
                                                            <Row>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Date</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="4" sm="4" md="4" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Name</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Account No</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="2" sm="2" md="2" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Transf. Amount</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Dept</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader" style={{padding:0}}>
                                                                    <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                        <span style={{fontWeight:"bold"}}>Emp. Number</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="rowDataWraperv1">
                                                            <Row>
                                                                <Col>
                                                                {this.state.dataKBBD.length > 0 && this.state.dataKBBD.map((dataKBBD,index) =>
                                                                dataKBBD.status_cara_penggajian != "TRANSFER"&&
                                                                    <Row key={index}>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Transf. Date</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="4" sm="4" md="4" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NAMA}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignLeft dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NO_REKENING}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="2" sm="2" md="2" className="detailKaryawanAlignRight dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{this.formatNumber(dataKBBD.TOTAL_GAJI_DITERIMA)}</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>Dept</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs="1" sm="1" md="1" className="detailKaryawanAlignCenter dataKBBHeader">
                                                                            <div style={{paddingTop:"5pt",paddingBottom:"5pt"}}>
                                                                                <span>{dataKBBD.NIK}</span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:15,paddingBottom:5}}>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data KBB PT SCU</span>
                                                        </button>
                                                    }
                                                    filename={`Data KBB PT SCU ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBTRANSFERSCU} name={`Data KBB PT SCU`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data KBB PT JAYGEE</span>
                                                        </button>
                                                    }
                                                    filename={`Data KBB PT JAYGEE ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBTRANSFERJAYGEE} name={`Data KBB PT JAYGEE`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:15,paddingBottom:5}}>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data CASH PT SCU</span>
                                                        </button>
                                                    }
                                                    filename={`Data CASH PT SCU ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBCASHSCU} name={`Data CASH PT SCU`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                            <Col>
                                                <ExcelFile 
                                                    element={
                                                        <button className="btn btn-secondary btn-block">
                                                            <i class="fa fa-download" aria-hidden="true" style={{color:"#ffffff"}}></i>
                                                            <span style={{marginLeft:5,color:"#ffffff",fontWeight:"bold"}}>Download Data CASH PT JAYGEE</span>
                                                        </button>
                                                    }
                                                    filename={`Data CASH PT JAYGEE ${moment(this.state.dataKBBH.periode_awal).format('DD MMMM')} TO ${moment(this.state.dataKBBH.periode_akhir).format('DD MMMM')} ${moment(this.state.dataKBBH.periode_akhir).format('YYYY')}`}
                                                >
                                                    <ExcelSheet data={this.state.dataKBBCASHJAYGEE} name={`Data CASH PT JAYGEE`}>
                                                        <ExcelColumn label="Transf. Date" value="akhirBulan"/>
                                                        <ExcelColumn label="Emp. Name" value="NAMA"/>
                                                        <ExcelColumn label="Account No" value="NO_REKENING"/>
                                                        <ExcelColumn label="Transf. Amount" value="TOTAL_GAJI_DITERIMA"/>
                                                        <ExcelColumn label="Dept" value="Staff"/>
                                                        <ExcelColumn label="Emp. Number" value="NIK"/>
                                                    </ExcelSheet>
                                                </ExcelFile>
                                            </Col>
                                        </Row>
                                    </div>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      authToken: state.reducer.authToken,
      userinfo: state.reducer.userinfo
    };
  };
  
  export default withRouter(connect(mapStateToProps)(dataKbb));